import React, { useCallback, useContext, useEffect, useState } from "react";
import { IoMdAddCircle } from "react-icons/io";
import { Box, Flex, Grid, Image, Stack, Text } from "@chakra-ui/react";
import { BiBrushAlt } from "react-icons/bi";

import { Header } from "../../../../components/Contents/Resources/Cards/Cards.element";

import ModalCalendar from "./ModalCalendar";

import calendarImg from "../../../../assets/img/icons/calendar.png";
import bigCalendarImg from "../../../../assets/MyNegociation/calendar_big.png";
import cartImg from "../../../../assets/img/icons/cart.png";
import basketImg from "../../../../assets/img/icons/cesta.png";
import drugstoreImg from "../../../../assets/img/icons/farmacia.png";
import storeImg from "../../../../assets/img/icons/small_store.png";

import { filterComponent } from "../../../../store/Navigation/types";

import { IStateFilters } from "../../../../store/SideFilters/types";
import TableCalendar from "./TableCalendar/Table";
import { FormatStringDataBr } from "../../../../components/Graphics/Resource/GraphicsFunctions";
import FiltersList, {
  INITIAL_FILTERS,
} from "../../../../components/SideFilterMenu/Resource/FiltersList";
import { stateMarketShare } from "../../../../store/SideFilters/RequestFilters/types";

// config system
import { useRequestCalendar } from "../../../../services/Hooks/MyNegociation/useRequestCalendar";
import LoadingComponent from "../../../../components/LoadingComponent";
import Card from "../../../../components/Card";
import { theme } from "../../../../styles/theme";
import { AddNegotiation } from "./styles";
import { ConfigContext } from "../../../../services/Context/ConfigContext";
import { getCurrentQuarterMonths } from "../../../../Resource/functions";

interface ownProps {
  renderSideFilter(data: filterComponent[]): void;
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
  DStateFilters: any;
}

const ViewCalendar: React.FC<ownProps> = ({
  renderSideFilter,
  SFilters,
  SAllFilters,
  DStateFilters,
}: ownProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [navigation, setNavigation] = useState<number>(Math.floor(1 / 3) + 1);

  // new Date().getMonth()

  const { config } = useContext(ConfigContext);

  const openModal = () => {
    setShow(!show);
  };

  const calendar = useRequestCalendar(SFilters);

  const renderFilter = useCallback(() => {
    if (config.showSideFilters.myExecution)
      renderSideFilter(
        FiltersList(
          SAllFilters,
          SFilters,
          {
            ...INITIAL_FILTERS,
            subChannel: true,
            banner: true,
            region: true,
            uf: true,
            storeCNPJ: true,
            // commercialStructureDirector: true,
            // commercialStructureManager: true,
            // commercialStructureSales: true,
          },
          DStateFilters
        )
      );
  }, [SAllFilters, SFilters, renderSideFilter]);

  const navigationQuarter = (quarter: number) => {
    if (quarter < 0) {
      if (navigation !== 1) setNavigation(navigation + quarter);
    } else if (navigation !== 4) {
      setNavigation(navigation + quarter);
    }
  };

  const getCurrentMonths = () => {
    const quarterMonths = getCurrentQuarterMonths(
      calendar.data?.data.months || [],
      navigation
    );

    return `Trimestre: 1 | Meses: ${quarterMonths[0] || "------"} a ${
      quarterMonths[quarterMonths.length - 1] || "------"
    }`;
  };

  useEffect(() => {
    renderFilter();
  }, [renderFilter]);

  return (
    <>
      <ModalCalendar show={show} setShow={openModal}>
        <Header>
          <h1>CONFIGURAÇÃO DE NEGOCIAÇÕES</h1>
        </Header>
      </ModalCalendar>
      <Grid templateRows="auto 1fr" h="calc(100% - 5px)">
        <Card title="Premissas do Calendário Promocional" bg="transparent">
          <Flex color="#7A7A7A" minH="160px" w="100%">
            <Flex bg="white" w="100%">
              <Stack ml="10px" w="calc(100% - 215px)">
                <Flex align="center">
                  <Text color={theme.colors.numberCards} fontSize="40px">
                    1
                  </Text>
                  <Image
                    src={calendarImg}
                    alt="Graphics"
                    w="32px"
                    h="32px"
                    mx="10px"
                  />
                  <Stack>
                    <Text>Periodo:</Text>
                    <Text as="span">
                      Selecione a data de <b>inicio e fim</b>
                    </Text>
                  </Stack>
                </Flex>
                <Stack mt="0px !important">
                  <Text>
                    <b>Período selecionado:</b>
                  </Text>
                  <Text as="span">
                    {`De ${FormatStringDataBr(
                      SFilters.promoFilter.dateInitial
                    )} até
                          ${FormatStringDataBr(
                            SFilters.promoFilter.dateFinal
                          )}`}
                  </Text>
                  <Text mt="5px !important">
                    <b>Praça Selecionada:</b>
                  </Text>
                  <Text as="span">
                    {`Região: ${FormatStringDataBr(
                      SFilters.promoFilter.region.selectedFilterName || "Todos"
                    )} | UF: ${FormatStringDataBr(
                      SFilters.promoFilter.uf.selectedFilterName || "Todos"
                    )}`}
                  </Text>
                </Stack>
              </Stack>
              <Flex mx="5px" justifyContent="end" align="center">
                <Image src={bigCalendarImg} w="90%" h="85%" />
              </Flex>
            </Flex>
            <Flex bg="white" w="100%" mx="5px">
              <Stack ml="10px" w="calc(100% - 215px)">
                <Flex align="center">
                  <Text color={theme.colors.numberCards} fontSize="40px">
                    2
                  </Text>
                  <Image
                    src={cartImg}
                    alt="Graphics"
                    w="32px"
                    h="32px"
                    mx="10px"
                  />
                  <Stack>
                    <Text>Categoria:</Text>
                    <Text as="span">
                      <b>
                        {SFilters.promoFilter.category?.selectedFilterName ||
                          "Todas"}
                      </b>
                    </Text>
                  </Stack>
                </Flex>
                <Stack mt="12px !important">
                  <Text>
                    <b>Marcas Selecionadas:</b>
                  </Text>
                  <Text as="span">
                    {SFilters.promoFilter.brand.selectedFilterName || "Todos"}
                  </Text>
                </Stack>
              </Stack>
              <Flex mx="5px" justifyContent="end" align="center">
                <Image src={basketImg} w="90%" h="85%" />
              </Flex>
            </Flex>
            <Flex bg="white" w="100%">
              <Stack ml="10px" w="calc(100% - 215px)">
                <Flex align="center">
                  <Text color={theme.colors.numberCards} fontSize="40px">
                    3
                  </Text>
                  <Image
                    src={storeImg}
                    alt="Graphics"
                    w="32px"
                    h="32px"
                    mx="10px"
                  />
                  <Stack>
                    <Text>Canal:</Text>
                    <Text as="span">
                      <b>
                        {SFilters.promoFilter.subChannel?.selectedFilterName ||
                          "Todos"}
                      </b>
                    </Text>
                  </Stack>
                </Flex>
                <Stack mt="0px !important">
                  <Text>
                    <b>Bandeiras Selecionadas:</b>
                  </Text>
                  <Text as="span">
                    {SFilters.promoFilter.banner?.selectedFilterName || "Todas"}
                  </Text>
                  <Text mt="5px !important">
                    <b>Lojas:</b>
                  </Text>
                  <Text as="span">5 Lojas</Text>
                </Stack>
              </Stack>
              <Flex mx="5px" justifyContent="end" align="center">
                <Image src={drugstoreImg} w="90%" h="85%" />
              </Flex>
            </Flex>
          </Flex>
        </Card>
        <Card title="Evidências das Execuções" mt="5px" bg="transparent">
          <Stack bg="white" w="100%" color="black">
            <Grid
              templateColumns="repeat(3, 1fr)"
              my={2}
              borderBottom="1px solid lightgrey"
              pb="12px"
              mx="5px"
            >
              <AddNegotiation type="button" onClick={() => openModal()}>
                <IoMdAddCircle size={20} /> ADICIONAR NEGOCIAÇÕES
              </AddNegotiation>
              <Stack align="center" justifyContent="center">
                <Text fontWeight="bold" m="0 !important">
                  Exibindo:
                </Text>
                <Text m="0 !important">{getCurrentMonths()}</Text>
              </Stack>
              {/* <Flex alignItems="center" justifyContent="right">
                <BsFillArrowLeftCircleFill
                  color="#0068b7"
                  cursor="pointer"
                  size={30}
                  onClick={() => navigationQuarter(-1)}
                />
                <Text pl="5px" fontSize="13px">
                  Trimestre Anterior
                </Text>
                <Text px={2}>|</Text>
                <Text pr="5px" fontSize="13px">
                  Próximo Trimestre
                </Text>
                <BsFillArrowRightCircleFill
                  color="#0068b7"
                  cursor="pointer"
                  size={30}
                  onClick={() => navigationQuarter(1)}
                />
              </Flex> */}
              <Box />
            </Grid>
            {(calendar.isLoading && <LoadingComponent />) ||
              (calendar.data && calendar.data.total > 0 && (
                <TableCalendar
                  structData={calendar.data?.data}
                  currentQuarter={navigation}
                />
              )) || (
                <Stack
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <BiBrushAlt size={45} color="#7A7A7A" />
                  <Text
                    mt="15px"
                    color="#7A7A7A"
                    fontWeight="bold"
                    fontSize="16px"
                  >
                    Não existe dados disponiveis para a sua seleção.
                  </Text>
                </Stack>
              )}
          </Stack>
        </Card>
      </Grid>
    </>
  );
};

export default ViewCalendar;
