import { Spinner, Stack, Text } from "@chakra-ui/react";
import React, { createContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IConfigurationSystem, IModules } from "../Hooks/config/types";
import { useConfigSystem } from "../Hooks/config/useConfigSystem";
import * as ANavigation from "../../store/Navigation/actions";
import {
  ENavSubMenu,
  IMainMenu,
  INavMainMenu,
} from "../../store/Navigation/types";

interface IContextConfig {
  config: IConfigurationSystem;
  setConfig(configs: IConfigurationSystem): void;
}

const INITIAL_CONFIGURATION: IConfigurationSystem = {
  fonts: {
    titles: "24px",
    legends: "12px",
    graphics: "11px",
  },
  general: {
    id: "",
    industryName: "",
    logoBip: "",
    promoIndustryId: "",
    referenceDate: "",
    referenceDateMyExecution: "",
    startDate: "",
    hasPromo: true,
  },
  modules: {
    myExecution: {
      active: true,
      cards: {
        extraExposure: {
          activate: true,
          label: "",
          subLabel: "",
        },
        complianceExtraExposure: {
          activate: true,
          label: "",
          subLabel: "",
        },
        perfectStore: {
          activate: false,
          label: "",
          subLabel: "",
        },
        presence: {
          activate: true,
          label: "",
          subLabel: "",
        },
        price: {
          activate: true,
          label: "",
          subLabel: "",
        },
        shareOfShelf: {
          activate: true,
          label: "",
          subLabel: "",
        },
        yellowPharmacy: {
          activate: false,
          label: "",
          subLabel: "",
        },
      },
    },
    myNegotiation: {
      active: true,
      subModules: {
        calendar: true,
        compliance: false,
        financialPlanning: false,
        ROI: false,
      },
    },
    onePage: {
      active: true,
      subModules: {
        banner: false,
        product: true,
        region: false,
        subChannel: false,
      },
    },
    promo: {
      active: true,
      subModules: {
        book: true,
        day: false,
      },
    },
  },
  showSideFilters: {
    myDay: true,
    myExecution: true,
    myNegociation: true,
    onePage: true,
  },
  theme: {
    cardHeaderBackgroundColor: "#152b68",
    cardHeaderTextColor: "#FFFFFF",
    chartPrimaryColor: "sem valor",
    chartSecondaryColor: "#ffffff",
    chartThirdColor: "sem valor",
    profile: "#000000",
    headerColor: "#6ec1e4",
    labelColor: "#ffffff",
    mainColor: "#6ec1e4",
    primaryColor: "#002060",
    secondaryColor: "#B4C7E7",
    selectedColor: "#6ec1e4",
    sidebarColor: "#6ec1e4",
    subHeaderColor: "#ffffff",
    textColor: "#000000",
    waterfallNegativeColor: "sem valor",
    waterfallPositiveColor: "sem valor",
    waterfallTotalColor: "sem valor",
  },
};

export const ConfigContext = createContext<IContextConfig>({
  config: INITIAL_CONFIGURATION,
  setConfig: () => null,
});

interface IOwnProps {
  children: JSX.Element;
}

interface IDispatchProps {
  DNavigation: {
    changeMenu(menu: IMainMenu): void;
    changeSubMenu(subMenu: ENavSubMenu): void;
  };
}

interface IStructureNavigation {
  menu: { index: INavMainMenu; name: string };
  subMenu: ENavSubMenu;
}

const defineFirstInitialScreen = (
  modules: IModules,
  isPromo: boolean
): IStructureNavigation => {
  if (modules.onePage.active)
    return {
      menu: { index: INavMainMenu.MY_ONE_PAGE, name: "One Page" },
      subMenu: isPromo ? ENavSubMenu.ONE_PAGE : ENavSubMenu.ONE_PAGE_ROCKET,
    };

  if (modules.myExecution.active)
    return {
      menu: { index: INavMainMenu.MY_EXECUTION, name: "Minha Execução" },
      subMenu: ENavSubMenu.ME_NPD,
    };

  if (modules.myNegotiation.active)
    return {
      menu: {
        index: INavMainMenu.MY_NEGOCIATIONS,
        name: "Calendario Promocional",
      },
      subMenu: ENavSubMenu.MN_CALENDAR,
    };

  if (modules.promo.active)
    return {
      menu: {
        index: INavMainMenu.PROMO,
        name: "Meu dia dia",
      },
      subMenu: ENavSubMenu.MD_BOOK,
    };

  return {
    menu: { index: INavMainMenu.PROMO, name: "Promo" },
    subMenu: ENavSubMenu.MD_BOOK,
  };
};

type props = IOwnProps & IDispatchProps;

const ContextConfiguration: React.FC<props> = ({
  DNavigation,
  children,
}: props) => {
  const [config, setConfig] = useState<IConfigurationSystem>(
    INITIAL_CONFIGURATION
  );

  const { data, isLoading } = useConfigSystem();

  useEffect(() => {
    if (data) {
      const modulesActive = defineFirstInitialScreen(
        data.data.modules,
        data.data.general.hasPromo
      );

      DNavigation.changeMenu(modulesActive.menu);
      DNavigation.changeSubMenu(modulesActive.subMenu);
      setConfig(data.data);
    }
  }, [data]);

  return (
    <ConfigContext.Provider value={{ config, setConfig }}>
      {isLoading ? (
        <Stack w="100%" h="100%" align="center" justify="center">
          <Spinner size="lg" color="black" />
          <Text fontWeight="bold" fontSize="14px" color="black">
            Carregando thema...
          </Text>
        </Stack>
      ) : (
        children
      )}
    </ConfigContext.Provider>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  DNavigation: bindActionCreators(ANavigation, dispatch),
});

export default connect(null, mapDispatchToProps)(ContextConfiguration);
