import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useEffect, useState, useCallback, useContext } from "react";
import { Button, Flex, Grid, Image, Link, Stack, Text } from "@chakra-ui/react";
import { CgClose } from "react-icons/cg";
import { RiFileExcel2Line } from "react-icons/ri";
import { HiOutlineArrowDown } from "react-icons/hi";

import calandarImg from "../../../../assets/img/icons/calendar.png";
import lavarageIcon from "../../../../assets/MyDay/iconLavarage.svg";
import storeIcon from "../../../../assets/MyDay/store.svg";
import complianceIcon from "../../../../assets/MyDay/compliance.svg";
import boxIcon from "../../../../assets/MyDay/iconsBox.svg";
import priceIcon from "../../../../assets/MyDay/price.png";
import eyeIcon from "../../../../assets/MyDay/iconsEye.svg";
import kpIcon from "../../../../assets/MyDay/kpi.svg";
import cartImg from "../../../../assets/img/icons/cart.png";
import storeImg from "../../../../assets/img/icons/small_store.png";
import graphicBrazilImg from "../../../../assets/MyDay/mapa.png";
import basketImg from "../../../../assets/img/icons/cesta.png";
import drugstoreImg from "../../../../assets/img/icons/farmacia.png";
import excel from "../../../../assets/MyDay/excel.svg";
// import iconPPTX from "../../../../assets/img/icons/powerpoint.png";

import { GlobalProps } from "../../../../store";
import {
  IStateFilters,
  TPromoFilter,
} from "../../../../store/SideFilters/types";
import { useFetchEvidences } from "../../../../services/Hooks/Evidences";
import { FormatStringDataBr } from "../../../../components/Graphics/Resource/GraphicsFunctions";

// Actions
import * as ANavigation from "../../../../store/Navigation/actions";
import * as AFilters from "../../../../store/SideFilters/actions";

// types
import { filterComponent } from "../../../../store/Navigation/types";
import { stateMarketShare } from "../../../../store/SideFilters/RequestFilters/types";

// config system
import FiltersList, {
  INITIAL_FILTERS,
} from "../../../../components/SideFilterMenu/Resource/FiltersList";
import LoadingComponent from "../../../../components/LoadingComponent";
import Card from "../../../../components/Card";
import { theme } from "../../../../styles/theme";
import { ConfigContext } from "../../../../services/Context/ConfigContext";
import { UModalCalendar } from "../../MyNegotiations/ViewCalendar/ModalCalendar/styles";
import {
  ModalOverlay,
  ModalWrapper,
} from "../../../../components/Modal/styles";
import CardIndicator from "../../../../components/MyDay/CardIndicator";
import { useExportData } from "../../../../services/Hooks/MyDay/useExportData";
import Toast, { useToast } from "../../../../components/Toast";

interface IStateProps {
  SFilters: IStateFilters;
  SAllFilters: stateMarketShare;
}

interface IDispatchProps {
  DFilters: {
    changeFilterIsPromo(dataFilter: TPromoFilter): void;
    changeDateInitialFilter(payload: string): void;
    changeDateFinalFilter(payload: string): void;
    changeCnpjStore(payload: string): void;
  };

  DNavigation: {
    changeFilters(filters: filterComponent[]): void;
  };
}

type props = IStateProps & IDispatchProps;

const ExtractData: React.FC<props> = ({
  DNavigation,
  SFilters,
  SAllFilters,
  DFilters,
}: props) => {
  const [popUpExportExcel, setPopUpExportExcel] = useState<boolean>(false);
  const [kpiShareOfShelf, setKpiShareOfShelf] = useState<boolean>(false);
  const [kpiExtraExposure, setKpiExtraExposure] = useState<boolean>(false);
  const [kpiCompliance, setKpiCompliance] = useState<boolean>(false);
  const [kpiRupture, setKpiRupture] = useState<boolean>(false);
  const [kpiPerfectStore, setKpiPerfectStore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>("");
  const { showToast, setShowToast } = useToast();

  // const pages = evidences?.executionsEvidences.length;

  const { config } = useContext(ConfigContext);

  const { data, refetch } = useExportData({
    industryId: config.general.promoIndustryId,
    filters: SFilters,
    kpisSelected: {
      shareOfShelf: kpiShareOfShelf,
      extraExposure: kpiExtraExposure,
      compliance: kpiCompliance,
      rupture: kpiRupture,
      perfectStore: kpiPerfectStore,
    },
  });

  const renderFilter = useCallback(() => {
    DNavigation.changeFilters(
      FiltersList(
        SAllFilters,
        SFilters,
        {
          ...INITIAL_FILTERS,
          period: true,
          typeExecution: true,
          executionLeverage: true,
          subChannel: true,
          businessUnitId: true,
          category: true,
          subCategory: true,
          banner: true,
          region: true,
          uf: true,
          isPromo: true,
          // commercialStructureDirector: true,
          // commercialStructureManager: true,
          // commercialStructureSales: true,
          storeCNPJ: true,
        },
        DFilters
      )
    );
  }, [SAllFilters, SFilters, DNavigation]);

  useEffect(() => {
    renderFilter();
  }, [renderFilter]);

  const validateExportData = () => {
    if (
      SFilters.promoFilter.dateInitial === "dd/mm/aaaa" ||
      SFilters.promoFilter.dateFinal === "dd/mm/aaaa"
    ) {
      setMessageError("Selecione uma data inicial e uma data final!");
      setShowToast();
      return true;
    }

    if (SFilters.promoFilter.dateInitial > SFilters.promoFilter.dateFinal) {
      setMessageError("A data inicial não pode ser maior que a final!");
      setShowToast();
      return true;
    }

    if (
      !kpiCompliance &&
      !kpiExtraExposure &&
      !kpiShareOfShelf &&
      !kpiRupture &&
      !kpiPerfectStore
    ) {
      setMessageError("Selecione ao menos um KPI para exportar!");
      setShowToast();
      return true;
    }

    return false;
  };

  const exportExcelSelected = () => {
    setIsLoading(true);
    if (!validateExportData()) {
      refetch().finally(() => setIsLoading(false));
      setPopUpExportExcel(true);
    }
  };

  return (
    <>
      <Toast
        {...{ showToast, setShowToast }}
        width="380px"
        margin="24px auto 0 auto"
        toastType="error"
        text={messageError}
      />
      {popUpExportExcel && (
        <>
          <ModalOverlay className="remove" />
          <ModalWrapper className="remove">
            <UModalCalendar width="30vw" className="remove">
              {(false && (
                <LoadingComponent text="Preparando os dados para exportação!" />
              )) || (
                <Stack>
                  <Flex justifyContent="flex-end" cursor="pointer">
                    <CgClose
                      size={30}
                      color={theme.colors.button}
                      onClick={() => setPopUpExportExcel(false)}
                    />
                  </Flex>
                  <Flex
                    w="100%"
                    alignItems="center"
                    p="50px 10px"
                    justify="space-around"
                  >
                    {(isLoading && <LoadingComponent />) || (
                      <>
                        {kpiRupture && (
                          <Stack alignItems="center">
                            <RiFileExcel2Line color="green" size={85} />
                            <Text
                              color="black"
                              p="5px 10px"
                              background="#dfdfdf"
                              w="100%"
                              textAlign="center"
                              borderRadius="8px"
                              mt="5px !important"
                              mb="8px !important"
                            >
                              Rupture
                            </Text>
                            <Link
                              href={data?.urlRupture}
                              _focus={{
                                outline: false,
                              }}
                              download
                            >
                              <Flex
                                w="45px"
                                h="40px"
                                borderRadius="50%"
                                justify="center"
                                align="center"
                                cursor="pointer"
                                _hover={{
                                  background: "#befdc3",
                                  transition: "0.4s",
                                }}
                                boxShadow="2px 3px 10px #00000055"
                              >
                                <HiOutlineArrowDown color="black" size="23px" />
                              </Flex>
                            </Link>
                          </Stack>
                        )}
                        {kpiShareOfShelf && (
                          <Stack alignItems="center">
                            <RiFileExcel2Line color="green" size={85} />
                            <Text
                              color="black"
                              p="5px 10px"
                              background="#dfdfdf"
                              w="100%"
                              textAlign="center"
                              borderRadius="8px"
                              mt="5px !important"
                              mb="8px !important"
                            >
                              Espaço de gondola
                            </Text>
                            <Link
                              href={data?.urlShareOfShelf}
                              _focus={{
                                outline: false,
                              }}
                              download
                            >
                              <Flex
                                w="45px"
                                h="40px"
                                borderRadius="50%"
                                justify="center"
                                align="center"
                                cursor="pointer"
                                _hover={{
                                  background: "#ade9b2",
                                  transition: "0.4s",
                                }}
                                boxShadow="2px 3px 10px #00000055"
                              >
                                <HiOutlineArrowDown color="black" size="23px" />
                              </Flex>
                            </Link>
                          </Stack>
                        )}
                        {kpiExtraExposure && (
                          <Stack alignItems="center">
                            <RiFileExcel2Line color="green" size={85} />
                            <Text
                              color="black"
                              p="5px 10px"
                              background="#dfdfdf"
                              w="100%"
                              textAlign="center"
                              borderRadius="8px"
                              mt="5px !important"
                              mb="8px !important"
                            >
                              Pontos Extras
                            </Text>
                            <Link
                              href={data?.urlExtraExposure}
                              _focus={{
                                outline: false,
                              }}
                              download
                            >
                              <Flex
                                w="45px"
                                h="40px"
                                borderRadius="50%"
                                justify="center"
                                align="center"
                                cursor="pointer"
                                _hover={{
                                  background: "#ade9b2",
                                  transition: "0.4s",
                                }}
                                boxShadow="2px 3px 10px #00000055"
                              >
                                <HiOutlineArrowDown color="black" size="23px" />
                              </Flex>
                            </Link>
                          </Stack>
                        )}

                        {kpiCompliance && (
                          <Stack alignItems="center">
                            <RiFileExcel2Line color="green" size={85} />
                            <Text
                              color="black"
                              p="5px 10px"
                              background="#dfdfdf"
                              w="100%"
                              textAlign="center"
                              borderRadius="8px"
                              mt="5px !important"
                              mb="8px !important"
                            >
                              Negociação
                            </Text>
                            <Link
                              href={data?.urlCompliance}
                              _focus={{
                                outline: false,
                              }}
                              download
                            >
                              <Flex
                                w="45px"
                                h="40px"
                                borderRadius="50%"
                                justify="center"
                                align="center"
                                cursor="pointer"
                                _hover={{
                                  background: "#ade9b2",
                                  transition: "0.4s",
                                }}
                                boxShadow="2px 3px 10px #00000055"
                              >
                                <HiOutlineArrowDown color="black" size="23px" />
                              </Flex>
                            </Link>
                          </Stack>
                        )}
                        {kpiPerfectStore && (
                          <Stack alignItems="center">
                            <RiFileExcel2Line color="green" size={85} />
                            <Text
                              color="black"
                              p="5px 10px"
                              background="#dfdfdf"
                              w="100%"
                              textAlign="center"
                              borderRadius="8px"
                              mt="5px !important"
                              mb="8px !important"
                            >
                              Loja perfeita
                            </Text>
                            <Link
                              href={data?.urlPerfectStore}
                              _focus={{
                                outline: false,
                              }}
                              download
                            >
                              <Flex
                                w="45px"
                                h="40px"
                                borderRadius="50%"
                                justify="center"
                                align="center"
                                cursor="pointer"
                                _hover={{
                                  background: "#ade9b2",
                                  transition: "0.4s",
                                }}
                                boxShadow="2px 3px 10px #00000055"
                              >
                                <HiOutlineArrowDown color="black" size="23px" />
                              </Flex>
                            </Link>
                          </Stack>
                        )}
                      </>
                    )}
                  </Flex>
                </Stack>
              )}
            </UModalCalendar>
          </ModalWrapper>
        </>
      )}
      <Grid templateRows="auto 1fr" h="calc(100% - 5px)">
        <Card title="Premissas da Extração de Dados" bg="transparent">
          <Flex color="#7A7A7A" minH="160px" w="100%">
            <Flex bg="white" w="100%">
              <Stack ml="10px" w="calc(100% - 215px)">
                <Flex align="center">
                  <Text color={theme.colors.numberCards} fontSize="40px">
                    1
                  </Text>
                  <Image
                    src={calandarImg}
                    alt="Graphics"
                    w="32px"
                    h="32px"
                    mx="10px"
                  />
                  <Stack>
                    <Text>Periodo:</Text>
                    <Text as="span">
                      Selecione a data de <b>inicio e fim</b>
                    </Text>
                  </Stack>
                </Flex>
                <Stack mt="0px !important">
                  <Text>
                    <b>Período selecionado:</b>
                  </Text>
                  <Text as="span">
                    {`De ${FormatStringDataBr(
                      SFilters.promoFilter.dateInitial
                    )} até
                            ${FormatStringDataBr(
                              SFilters.promoFilter.dateFinal
                            )}`}
                  </Text>
                  <Text mt="5px !important">
                    <b>Praça Selecionada:</b>
                  </Text>
                  <Text as="span">
                    {`Região: ${FormatStringDataBr(
                      SFilters.promoFilter.region.selectedFilterName || "Todos"
                    )} | UF: ${FormatStringDataBr(
                      SFilters.promoFilter.uf.selectedFilterName || "Todos"
                    )}`}
                  </Text>
                </Stack>
              </Stack>
              <Flex mx="5px" justifyContent="end" align="center">
                <Image src={graphicBrazilImg} w="90%" h="85%" />
              </Flex>
            </Flex>
            <Flex bg="white" w="100%" mx="5px">
              <Stack ml="10px" w="calc(100% - 215px)">
                <Flex align="center">
                  <Text color={theme.colors.numberCards} fontSize="40px">
                    2
                  </Text>
                  <Image
                    src={cartImg}
                    alt="Graphics"
                    w="32px"
                    h="32px"
                    mx="10px"
                  />
                  <Stack>
                    <Text>Categoria:</Text>
                    <Text as="span">
                      <b>
                        {SFilters.promoFilter.category?.selectedFilterName ||
                          "Todas"}
                      </b>
                    </Text>
                  </Stack>
                </Flex>
                <Stack mt="12px !important">
                  <Text>
                    <b>Marcas Selecionadas:</b>
                  </Text>
                  <Text as="span">
                    {SFilters.promoFilter.brand.selectedFilterName || "Todos"}
                  </Text>
                </Stack>
              </Stack>
              <Flex mx="5px" justifyContent="end" align="center">
                <Image src={basketImg} w="90%" h="85%" />
              </Flex>
            </Flex>
            <Flex bg="white" w="100%">
              <Stack ml="10px" w="calc(100% - 215px)">
                <Flex align="center">
                  <Text color={theme.colors.numberCards} fontSize="40px">
                    3
                  </Text>
                  <Image
                    src={storeImg}
                    alt="Graphics"
                    w="32px"
                    h="32px"
                    mx="10px"
                  />
                  <Stack>
                    <Text>Canal:</Text>
                    <Text as="span">
                      <b>
                        {SFilters.promoFilter.subChannel?.selectedFilterName ||
                          "Todos"}
                      </b>
                    </Text>
                  </Stack>
                </Flex>
                <Stack mt="0px !important">
                  <Text>
                    <b>Bandeiras Selecionadas:</b>
                  </Text>
                  <Text as="span">
                    {SFilters.promoFilter.banner?.selectedFilterName || "Todas"}
                  </Text>
                  <Text mt="5px !important">
                    <b>Lojas:</b>
                  </Text>
                  <Text as="span">0 Lojas</Text>
                </Stack>
              </Stack>
              <Flex mx="5px" justifyContent="end" align="center">
                <Image src={drugstoreImg} w="90%" h="85%" />
              </Flex>
            </Flex>
          </Flex>
        </Card>
        <Card title="Indicadores" mt="5px" bg="transparent">
          <Stack w="100%" h="100%" bg="white">
            <Flex
              h="55px"
              my={2}
              borderBottom="1px solid lightgrey"
              pb="12px"
              mx="5px"
              pl="20px"
              pt="5px"
              justifyContent="space-between"
            >
              <Flex alignItems="center">
                <Image src={kpIcon} w="50px" />
                <Text ml="20px !important" fontSize={19} color="black">
                  Selecione os KPIs que deseja extrair
                </Text>
              </Flex>
              <Flex
                align="center"
                cursor="pointer"
                onClick={() => exportExcelSelected()}
              >
                <Text fontWeight="bold" pr="15px" color="black">
                  Exportar para
                </Text>
                <Image src={excel} w="50px" h="50px" />
              </Flex>
            </Flex>
            <Flex overflowY="hidden" flex={1} flexWrap="wrap" overflow="auto">
              <CardIndicator
                lavarages={[
                  { icon: lavarageIcon, name: "Presença" },
                  { icon: priceIcon, name: "Preço" },
                  { icon: boxIcon, name: "Estoque**" },
                ]}
                onChange={setKpiRupture}
              />
              {/* <CardIndicator
                lavarages={[{ icon: lavarageIcon, name: "Espaço de Gondola" }]}
                onChange={setKpiShareOfShelf}
              />
              <CardIndicator
                lavarages={[{ icon: eyeIcon, name: "Pontos Extras" }]}
                onChange={setKpiExtraExposure}
              /> */}
              <CardIndicator
                lavarages={[
                  { icon: complianceIcon, name: "Calendario Promocional" },
                ]}
                onChange={setKpiCompliance}
              />
              {/* <CardIndicator
                lavarages={[{ icon: storeIcon, name: "Loja Perfeita" }]}
                onChange={setKpiPerfectStore}
              />  */}
            </Flex>
          </Stack>
        </Card>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  navigation: state.navigation,
  SFilters: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  DFilters: bindActionCreators(AFilters, dispatch),
  DNavigation: bindActionCreators(ANavigation, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtractData);
