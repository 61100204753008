import { connect } from "react-redux";
import {
  Box,
  Flex,
  Image,
  Input,
  Stack,
  Text,
  Spinner as ChakraSpinner,
  Checkbox,
} from "@chakra-ui/react";
import React, {
  ChangeEvent,
  createRef,
  ReactEventHandler,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import { RiFileExcel2Fill } from "react-icons/ri";
import XLSX from "xlsx";

import VMasker from "vanilla-masker";
import {
  ModalOverlay,
  ModalWrapper,
  UModalCalendar,
  ControlHeader,
  ContainerInput,
  ContainerAlavancas,
  ModalButton,
  CardUploadContainer,
  ContainerTableModal,
  ButtonAlavanca,
} from "./styles";

import {
  ContainerFlex,
  Container,
  WrapperSubView,
} from "../../../../../components/Contents/Resources/SubView/subView.element";

import Filters from "../../../../../components/Filters/filters";
import { TableSubView } from "../../../../../components/Contents/Resources/SubView/subView";

import {
  CustomRadio,
  handleSelectedItem,
  IListProductSelected,
} from "./CustomRadio";

import calendarIcon from "../../../../../assets/img/icons/calendar.png";
import moneyIcon from "../../../../../assets/MyNegociation/Modal/money.svg";
import { Header } from "../../../../../components/Contents/Resources/Cards/Cards.element";

import { GlobalProps } from "../../../../../store";

import {
  filter,
  stateMarketShare,
  TFilterSegment,
} from "../../../../../store/SideFilters/RequestFilters/types";
import api from "../../../../../services/api";
import { FormatStringData } from "../../../../../components/Graphics/Resource/GraphicsFunctions";
import Toast, { useToast } from "../../../../../components/Toast";
import {
  IFilter,
  IStateFilters,
  TFilterBrand,
} from "../../../../../store/SideFilters/types";
import { INITIAL_STATE } from "../../../../../store/SideFilters";

// imagens
import { theme } from "../../../../../styles/theme";
import { ConfigContext } from "../../../../../services/Context/ConfigContext";
import { convertDate } from "../../../../../Resource/functions";
import {
  IProductData,
  useRequestProducts,
} from "../../../../../services/Hooks/MyNegociation/useRequestProducts";
import { useRequestLeverage } from "../../../../../services/Hooks/MyNegociation/useRequestLeverage";
import ButtonExcelImport from "../../../../../components/myNegociation/excelButtonImport";
import LoadingComponent from "../../../../../components/LoadingComponent";

interface PropsModal {
  show: boolean;
  setShow: () => void;
  children?: ReactNode;
}

interface PropsCustomHeader {
  stage: string;
  img: string;
  children: ReactNode;
}

interface PropsCardUpload {
  color?: string;
  border?: boolean;
  children: ReactNode;
}

const CustomerHeader: React.FC<PropsCustomHeader> = ({
  stage,
  img,
  children,
}: PropsCustomHeader): JSX.Element => {
  return (
    <Header background="#E7E6E6" color="#7A7A7A" marginBottom=" 0 0 12px">
      <ControlHeader>
        <h2>{stage}</h2>
        <img src={img} alt="" />
        {children}
      </ControlHeader>
    </Header>
  );
};

const CardUpload: React.FC<PropsCardUpload> = ({
  color,
  border,
  children,
}): JSX.Element => {
  return (
    <CardUploadContainer border={border} color={color}>
      {children}
    </CardUploadContainer>
  );
};

CardUpload.defaultProps = {
  border: false,
  color: "#7A7A7A",
  children: "",
};

CardUpload.propTypes = {
  border: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.node,
};

interface sendCNPJ {
  activeStores: {
    activeStores: string[];
    count: 0;
  };
  invalidStores: {
    count: 0;
    invalidStores: string[];
  };
}

interface IStateProps {
  SFilter: IStateFilters;
  SAllFilters: stateMarketShare;
}

interface ISelectedFilter {
  category: {
    nameFilter: string;
    listFilter: TFilterBrand[];
  };
  brand: {
    nameFilter: string;
    listFilter: TFilterSegment[];
  };
  segment: string;
  isMSL: string;
}

type Props = IStateProps & PropsModal;

const ModalCalendar: React.FC<Props> = ({
  show,
  setShow,
  SAllFilters,
}: Props) => {
  const today = new Date().toISOString().slice(0, 10);
  // const [addFinancialPlanning, setAddFinancialPlanning] =
  //   useState<boolean>(false);
  const { showToast, setShowToast } = useToast();
  const [toastType, setToastType] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");
  const [alavanca, setAlavanca] = useState("Nenhuma");
  const [initialDate, setInitialDate] = useState("dd/mm/aaaa");
  const [finishDate, setfinishDate] = useState("dd/mm/aaaa");
  const [fileName, setFileName] = useState<string>("");
  const [fileNameImportSku, setFileNameImportSku] = useState<string>("");
  const [buttonTitle, setButtonTitle] = useState<string>("Importar");
  const [bgButton, setBgButton] = useState<string>(theme.colors.button);
  const [alavancaSelected, setAlavancaSelected] = useState<string>("");
  const [filtButton, setFiltButton] = useState<string>("brightness(0.8)");
  const [marca, setMarca] = useState<string[]>([
    " Todos, ",
    "Todos, ",
    "Todos, ",
    "Todos ",
    "Todos ",
  ]);
  const [validateDate, setValidateDate] = useState<boolean>(false);
  const [payloadCNPJ, setPayloadCNPJ] = useState<sendCNPJ>();
  const [productSelected, setProductSelected] = useState<
    IListProductSelected[]
  >([]);
  const [withAttendance, setWithAttendance] = useState<number>(0);
  const [withOutAttendance, setWithOutAttendance] = useState<number>(0);
  const [qtdPrateleiras, setQtdPrateleiras] = useState<number>(0);
  const [listCnpjs, setListCnpjs] = useState<string[]>();
  const [mainListProduct, setMainListProduct] = useState<IProductData[]>([]);
  const [listCategory, setListCategory] = useState<IProductData[]>([]);
  const [filterBrand, setFilterBrand] = useState<IProductData[]>([]);
  const [filterSegment, setFilterSegment] = useState<IProductData[]>([]);
  const [filterIsMsl, setFilterIsMls] = useState<IProductData[]>([]);
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [filterSelected, setFilterSelected] = useState<ISelectedFilter>({
    category: {
      nameFilter: "Todas",
      listFilter: [],
    },
    brand: {
      nameFilter: "Todas",
      listFilter: [],
    },
    segment: "Todas",
    isMSL: "Todas",
  });
  const [isMSL, setIsMSL] = useState<number>(-1);
  const refInitialDate = createRef<HTMLInputElement>();
  const refFinishDate = createRef<HTMLInputElement>();

  const { config } = useContext(ConfigContext);
  const products = useRequestProducts();
  const leverages = useRequestLeverage();

  // const FinancPlanTableData: IDataFinancialPlanning = {
  //   codAction: 3218,
  //   gondolaEnd: pg,
  //   mainTable: [
  //     {
  //       brand: {
  //         qtdFamily: 2,
  //         name: "KMED",
  //       },
  //       family: "Hot",
  //       baseVolume: "8.500",
  //       ProjVolume: "12.500",
  //       investment: "R$ 49.950,00",
  //       investmentPercent: "33,3%",
  //       ROIProj: 1.7,
  //     },
  //     {
  //       family: "Message",
  //       baseVolume: "1.500",
  //       ProjVolume: "3.500",
  //       investment: "R$ 49.950,00",
  //       investmentPercent: "33,3%",
  //       ROIProj: 0.3,
  //     },
  //     {
  //       brand: {
  //         qtdFamily: 1,
  //         name: "BEPANTRIZ",
  //       },
  //       family: "Proteção",
  //       baseVolume: "2.000",
  //       ProjVolume: "3.000",
  //       investment: "R$ 49.950,00",
  //       investmentPercent: "33,3%",
  //       ROIProj: -0.1,
  //     },
  //     {
  //       brand: {
  //         qtdFamily: 1,
  //         name: "Total",
  //       },
  //       family: "",
  //       baseVolume: "12.000",
  //       ProjVolume: "19.000",
  //       investment: "R$ 150.000,00",
  //       investmentPercent: "100,0%",
  //       ROIProj: 1,
  //     },
  //   ],
  //   complementTable: [
  //     {
  //       investment: "R$ 49.950,00",
  //       realValume: "12.500",
  //       realROI: 1.7,
  //     },
  //     {
  //       investment: "R$ 49.950,00",
  //       realValume: "3.500",
  //       realROI: 0.3,
  //     },
  //     {
  //       investment: "R$ 49.950,00",
  //       realValume: "3.500",
  //       realROI: -0.1,
  //     },
  //     {
  //       investment: "R$ 49.950,00",
  //       realValume: "4.500",
  //       realROI: 2.6,
  //     },
  //     {
  //       investment: "R$ 49.950,00",
  //       realValume: "8.300",
  //       realROI: 3.4,
  //     },
  //     {
  //       investment: "R$ 49.950,00",
  //       realValume: "12.500",
  //       realROI: -1.7,
  //     },
  //     {
  //       investment: "R$ 150.000,00",
  //       realValume: "19.000",
  //       realROI: 1.0,
  //     },
  //   ],
  // };

  function resetComponent() {
    setInitialDate("dd/mm/aaaa");
    setfinishDate("dd/mm/aaaa");
    setAlavanca("Nenhuma");
    setFileName("");
    setBgButton(theme.colors.button);
    setButtonTitle("Importar");
    setAlavancaSelected("");
    setProductSelected([]);
    setFilterSelected({
      category: {
        listFilter: [],
        nameFilter: "Todas",
      },
      brand: {
        listFilter: [],
        nameFilter: "Todas",
      },
      isMSL: "Todas",
      segment: "Todas",
    });

    setMarca([" Todos, ", "Todos, ", "Todos, ", "Todos "]);

    if (listCnpjs) listCnpjs.length = 0;
    if (payloadCNPJ) payloadCNPJ.activeStores.count = 0;
    if (payloadCNPJ) payloadCNPJ.invalidStores.count = 0;
  }

  const filterItemCategory = async (id: string) => {
    let selectedFilter: filter = { id: "", label: "" };

    SAllFilters.data.myExecution.subviewOSA.secondary.categories.forEach(
      (item) => {
        if (item.id === id) {
          selectedFilter = item;
        }
      }
    );

    const filtredItens =
      SAllFilters.data.myExecution.subviewOSA.secondary.brands
        .filter((filterItem) => selectedFilter.id === filterItem.categoryId)
        .map((filtred) => {
          return filtred;
        });

    if (selectedFilter.id) {
      const productFiltered: IProductData[] = (products.data || [])
        .filter((item) => item.category === selectedFilter.id)
        .map((product) => {
          return product;
        });

      if (isMSL >= 0) {
        const msl = isMSL === 1;
        const filteredProduct = productFiltered
          .filter((item) => item.msl === msl)
          .map((product) => {
            return product;
          });
        setMainListProduct(filteredProduct);
      } else {
        setMainListProduct(productFiltered);
      }

      setListCategory(productFiltered);
    } else if (isMSL >= 0) {
      const msl = isMSL === 1;
      const filteredProduct = (products.data || [])
        .filter((item) => item.msl === msl)
        .map((product) => {
          return product;
        });
      setMainListProduct(filteredProduct);
    } else {
      setMainListProduct(products.data || []);
    }

    setFilterSelected({
      ...filterSelected,
      category: { listFilter: filtredItens, nameFilter: selectedFilter.label },
      brand: { listFilter: [], nameFilter: "Todas" },
      segment: "Todas",
    });

    setMarca([
      ` ${selectedFilter.label}, `,
      "Todos, ",
      "Todos, ",
      `${marca[3]}`,
    ]);
  };

  const filterItemBrand = (id: string) => {
    let selectedFilter: filter = { id: "", label: "" };

    SAllFilters.data.myExecution.subviewOSA.secondary.brands.forEach((item) => {
      if (item.id === id) {
        selectedFilter = item;
      }
    });

    const filtredItens =
      SAllFilters.data.myExecution.subviewOSA.secondary.segments
        .filter((filterItem) => selectedFilter.id === filterItem.brandId)
        .map((filtred) => {
          return filtred;
        });

    if (selectedFilter.id) {
      const productBrand: IProductData[] = listCategory
        .filter((item) => item.sub_category === selectedFilter.id)
        .map((product) => {
          return product;
        });

      if (isMSL >= 0) {
        const msl = isMSL === 1;
        const filteredBrand = productBrand
          .filter((item) => item.msl === msl)
          .map((product) => {
            return product;
          });
        setMainListProduct(filteredBrand);
      } else {
        setMainListProduct(productBrand);
      }

      setFilterBrand(productBrand);
    } else if (isMSL >= 0) {
      const msl = isMSL === 1;
      const filteredProduct = listCategory
        .filter((item) => item.msl === msl)
        .map((product) => {
          return product;
        });
      setMainListProduct(filteredProduct);
    } else if (filterSelected.category.listFilter.length > 0) {
      setMainListProduct(listCategory);
    }

    setFilterSelected({
      ...filterSelected,
      brand: {
        nameFilter: selectedFilter.label,
        listFilter: filtredItens,
      },
      segment: "Todas",
    });

    setMarca([marca[0], `${selectedFilter.label}, `, "Todos, ", `${marca[3]}`]);
  };

  const filterItemSegments = (id: string) => {
    let selectedFilter: filter = { id: "", label: "" };

    SAllFilters.data.myExecution.subviewOSA.secondary.segments.forEach(
      (item) => {
        if (item.id === id) {
          selectedFilter = item;
        }
      }
    );

    if (selectedFilter.id) {
      const productBrand: IProductData[] = filterBrand
        .filter((item) => item.segment === selectedFilter.id)
        .map((product) => {
          return product;
        });

      if (isMSL >= 0) {
        const msl = isMSL === 1;
        const filteredBrand = productBrand
          .filter((item) => item.msl === msl)
          .map((product) => {
            return product;
          });
        setMainListProduct(filteredBrand);
      } else {
        setMainListProduct(productBrand);
      }

      setFilterSegment(productBrand);
    } else if (filterSelected.brand.listFilter.length > 0) {
      setMainListProduct(filterBrand);
    }

    setFilterSelected({ ...filterSelected, segment: selectedFilter.label });
    setMarca([marca[0], marca[1], `${selectedFilter.label}, `, `${marca[3]}`]);
  };

  const handleFilters = (id: string) => {
    let selectedFilter: filter = { id: "", label: "" };

    INITIAL_STATE.promoFilter.priorityItems.listFilters?.forEach(
      (item: IFilter) => {
        if (item.id === id) {
          selectedFilter = item;
        }
      }
    );

    const isProductMsl = selectedFilter.id === "true";

    let listProduct = filterIsMsl;
    setIsMSL(+isProductMsl);

    if (filterIsMsl.length === 0) {
      setFilterIsMls(mainListProduct);
      listProduct = mainListProduct;
    }

    if (filterSelected.category.nameFilter === "Todas") {
      if (selectedFilter.id === "") {
        setMainListProduct(listProduct);
        setIsMSL(-1);
      } else {
        const isMsList = listProduct
          .filter((item) => item.msl === isProductMsl)
          .map((product) => {
            return product;
          });
        setMainListProduct(isMsList);
      }
    } else if (filterSelected.segment !== "Todas") {
      if (selectedFilter.id === "") {
        setMainListProduct(filterSegment);
        setIsMSL(-1);
      } else {
        const isMsList = filterSegment
          .filter((item) => item.msl === isProductMsl)
          .map((product) => {
            return product;
          });

        setMainListProduct(isMsList);
      }
    } else if (filterSelected.brand.nameFilter !== "Todas") {
      if (selectedFilter.id === "") {
        setMainListProduct(filterBrand);
        setIsMSL(-1);
      } else {
        const isMsList = filterBrand
          .filter((item) => item.msl === isProductMsl)
          .map((product) => {
            return product;
          });
        setMainListProduct(isMsList);
      }
    } else if (filterSelected.category.nameFilter !== "Todas") {
      if (selectedFilter.id === "") {
        setMainListProduct(listCategory);
        setIsMSL(-1);
      } else {
        const isMsList = listCategory
          .filter((item) => item.msl === isProductMsl)
          .map((product) => {
            return product;
          });
        setMainListProduct(isMsList);
      }
    }

    setFilterSelected({ ...filterSelected, isMSL: selectedFilter.label });
    setMarca([marca[0], marca[1], marca[2], `${selectedFilter.label} `]);
  };

  const handleSelectedAlavancas = (
    alavancaNome: string,
    leverageId: string,
    shelfQuantity: number
  ) => {
    setAlavanca(alavancaNome);
    setAlavancaSelected(leverageId);
    setQtdPrateleiras(shelfQuantity);
  };

  const handleInitialDate = (e: ChangeEvent<HTMLInputElement>) => {
    const previousDate = e.target.value < today;
    let compareDate = false;

    if (finishDate !== "dd/mm/aaaa") {
      compareDate = convertDate(finishDate, "") < e.target.value;
    }

    setInitialDate(convertDate(e.target.value, "br"));

    if (previousDate || compareDate) {
      refInitialDate.current?.classList.add("error");
      setValidateDate(false);
      refFinishDate.current?.classList.remove("error");
      return;
    }

    setValidateDate(true);
    refInitialDate.current?.classList.remove("error");
    refFinishDate.current?.classList.remove("error");
  };

  const handlefinishDate = (e: ChangeEvent<HTMLInputElement>) => {
    const previousDate = e.target.value < today;
    let compareDate = false;

    if (initialDate !== "dd/mm/aaaa") {
      compareDate = convertDate(initialDate, "") > e.target.value;
    }

    setfinishDate(convertDate(e.target.value, "br"));

    if (previousDate || compareDate) {
      refFinishDate.current?.classList.add("error");
      setValidateDate(false);
      refInitialDate.current?.classList.remove("error");
      return;
    }

    setValidateDate(true);
    refFinishDate.current?.classList.remove("error");
    refInitialDate.current?.classList.remove("error");
  };

  const getCurrentDate = () => {
    const date = new Date();
    date.setDate(date.getDate());
    return date.toLocaleString("pt-BR").substr(0, 10);
  };

  const downloadExcel = () => {
    const day = new Date().getDay();
    const month = new Date().getMonth();
    const year = new Date().getFullYear();
    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: "Cnpjs invalidos",
      Subject: "correção dos cnpjs invalidos",
      Author: `${config.general.industryName} solutions`,
      CreatedDate: new Date(day, month, year),
    };
    if (payloadCNPJ) {
      const cnpjs = [["cnpjs"]];
      payloadCNPJ.invalidStores.invalidStores.forEach((item) => {
        cnpjs.push([item]);
      });
      const ws = XLSX.utils.aoa_to_sheet(cnpjs);
      if (ws) {
        const wscols = [{ wpx: 100 }];
        ws["!cols"] = wscols;
        XLSX.utils.book_append_sheet(wb, ws, "cnpjs inválidos");
        XLSX.writeFile(wb, `invalid_cnpjs_${getCurrentDate()}.xlsx`);
      }
    }
  };

  const handleTitleButton = () => {
    if (buttonTitle === "Importar" && fileName !== "") {
      setButtonTitle("");
      setBgButton("#FFBA57");
      api
        .post("validate_stores/", {
          stores: listCnpjs,
        })
        .then((payload) => {
          setBgButton("#037215");
          setButtonTitle("importado");
          setPayloadCNPJ(payload.data);
        });
    }
  };

  const handleImportSkusByEAN = (listEan: string[]) => {
    const productsImport: IProductData[] = [];

    if (products.data) {
      listEan.forEach((ean) => {
        products.data.forEach((product: IProductData) => {
          if (product.ean === ean) {
            productsImport.push(product);
          }
        });
      });
    }

    const selectedProducts: IListProductSelected[] = productsImport.map(
      (selected): IListProductSelected => {
        return {
          id: selected.id,
          observation: "",
          shelfSelecteds: [],
          tpr: 0.0,
        };
      }
    );

    setMainListProduct(productsImport);
    setProductSelected(selectedProducts);
  };

  interface IRequestExecution {
    products: IListProductSelected[];
    executionLeverage: string;
    storesCnpjs: string[];
    industryId: string;
    initialDate: string;
    finishDate: string;
  }

  interface IResponseOrderCreate {
    errors: string[];
    executions: string[];
    message: string;
    orders: string[];
    registred: boolean;
    storesWithAttendance: string[];
    storesWithOutAttendance: string[];
  }

  const sendRequestExecution = async () => {
    const payload: IRequestExecution = {
      initialDate: FormatStringData(initialDate),
      finishDate: FormatStringData(finishDate),
      storesCnpjs: payloadCNPJ ? payloadCNPJ.activeStores.activeStores : [],
      executionLeverage: alavancaSelected,
      industryId: config.general.promoIndustryId || "",
      products: productSelected,
    };
    setIsSendingRequest(true);
    try {
      await api.post("order_creation/", payload).then((response) => {
        const payloadStruct: IResponseOrderCreate = response.data;
        setWithAttendance(payloadStruct.storesWithAttendance.length);
        setWithOutAttendance(payloadStruct.storesWithOutAttendance.length);
        if (response.status === 200) {
          setToastType("success");
          setToastText(payloadStruct.message);
          setShowToast();
          resetComponent();
          setIsSendingRequest(false);
          setTimeout(() => {
            setShow();
          }, 2500);
        } else {
          setIsSendingRequest(false);
          setToastType("error");
          setToastText(payloadStruct.message);
        }
      });
    } catch (error) {
      setToastType("error");
      setToastText("Tivemos um problema, tente novamente");
      setShowToast();
      setIsSendingRequest(false);
    }
  };

  async function handleSaveButton() {
    if (
      alavancaSelected !== "0" &&
      fileName !== "" &&
      productSelected.length > 0
    ) {
      sendRequestExecution();
    } else {
      setToastType("info");
      setToastText("Por favor, preencha todos os campos");
      setShowToast();
    }
  }

  useEffect(() => {
    function handleSave() {
      if (
        validateDate &&
        alavancaSelected !== "" &&
        payloadCNPJ !== undefined &&
        productSelected.length > 0
      ) {
        setFiltButton("");
      } else {
        setFiltButton("brightness(0.8)");
      }
    }
    handleSave();
  }, [validateDate, alavancaSelected, payloadCNPJ, productSelected]);

  useEffect(() => {
    products.refetch();
    leverages.refetch();
  }, []);

  useEffect(() => {
    setMainListProduct(products.data || []);
  }, [products.data]);

  useEffect(() => {
    const listner = (e: KeyboardEvent) => {
      if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
        e.preventDefault();

        if (show) {
          setShow();
        }
      }
    };

    window.addEventListener("keyup", listner);

    return () => {
      window.removeEventListener("keyup", listner);
    };
  }, [show, setShow]);

  const isChecked = (id: string, index: number): boolean => {
    let isCheck = false;
    productSelected
      .filter((selectedItem) => selectedItem.id === id)
      .forEach((selectedProd) => {
        selectedProd.shelfSelecteds.forEach((item) => {
          if (item === index) {
            isCheck = true;
          }
        });
      });

    return isCheck;
  };

  const removeIndiceArray = (payload: IListProductSelected, index: number) => {
    const removedArray = payload.shelfSelecteds
      .filter((item) => item !== index)
      .map((product) => {
        return product;
      });

    while (payload.shelfSelecteds.shift());
    if (removedArray.length > 0) {
      removedArray.forEach((item) => payload.shelfSelecteds.push(item));
    }
  };

  const handleChecked = (index: number, product: IProductData) => {
    if (productSelected.find((item) => item.id === product.id)) {
      if (isChecked(product.id, index)) {
        productSelected
          .filter((prod) => prod.id === product.id)
          .forEach((itemFiltered) => {
            removeIndiceArray(itemFiltered, index);
          });
      } else {
        productSelected
          .filter((prod) => prod.id === product.id)
          .forEach((itemFiltered) => {
            itemFiltered.shelfSelecteds.push(index);
            itemFiltered.shelfSelecteds.sort();
          });
      }
    }
  };

  const mapQtdStands = (product: IProductData) => {
    const arrJsxElement: JSX.Element[] = [];

    for (let i = 1; i <= qtdPrateleiras; i += 1) {
      const jsxElement: JSX.Element = (
        <Checkbox
          isDisabled={handleSelectedItem(productSelected, product.id)}
          colorScheme="yellow"
          type="checkbox"
          id={i.toString()}
          onChange={() => handleChecked(i, product)}
          defaultChecked={isChecked(product.id, i)}
        >
          {i}
        </Checkbox>
      );
      arrJsxElement.push(jsxElement);
    }

    return arrJsxElement;
  };

  const formatMoney: ReactEventHandler<HTMLInputElement> = (e) => {
    const input = e.currentTarget;

    const valueInput = input.value.replace(/\D/g, "");
    VMasker(input).unMask();
    if (valueInput.length < 4) {
      input.value = VMasker.toPattern(valueInput, "R$ 9,99");
    } else if (valueInput.length < 5) {
      input.value = VMasker.toPattern(valueInput, "R$ 99,99");
    } else if (valueInput.length < 6) {
      input.value = VMasker.toPattern(valueInput, "R$ 999,99");
    } else if (valueInput.length < 7) {
      input.value = VMasker.toPattern(valueInput, "R$ 9.999,99");
    } else if (valueInput.length < 8) {
      input.value = VMasker.toPattern(valueInput, "R$ 99.999,99");
    } else if (valueInput.length < 9) {
      input.value = VMasker.toPattern(valueInput, "R$ 999.999,99");
    }
  };

  const addTPRinObject: ReactEventHandler<HTMLInputElement> = (e) => {
    const input = e.currentTarget;
    const amountFormated = input.value
      .replace(/[Rr$.]|\s|/gm, "")
      .replace(",", ".");
    const listModify = productSelected.map((item) => {
      if (item.id === input.id) {
        return {
          id: item.id,
          tpr: parseFloat(amountFormated),
          shelfSelecteds: item.shelfSelecteds,
          observation: item.observation,
        };
      }
      return item;
    });
    setProductSelected(listModify);
  };

  const addObservation: ReactEventHandler<HTMLInputElement> = (e) => {
    const input = e.currentTarget;
    const listModify = productSelected.map((item) => {
      if (item.id === input.name) {
        return {
          id: item.id,
          tpr: item.tpr,
          shelfSelecteds: item.shelfSelecteds,
          observation: input.value,
        };
      }
      return item;
    });

    setProductSelected(listModify);
  };

  const getValueInput = (id: string): string => {
    let currentValue = "";
    productSelected.forEach((item) => {
      if (item.id === id && item.tpr !== 0) {
        currentValue = item.tpr.toFixed(2);
      }
    });
    if (currentValue.length < 5) {
      currentValue = VMasker.toPattern(currentValue, "R$ 9,99");
    } else if (currentValue.length < 6) {
      currentValue = VMasker.toPattern(currentValue, "R$ 99,99");
    } else if (currentValue.length < 7) {
      currentValue = VMasker.toPattern(currentValue, "R$ 999,99");
    } else if (currentValue.length < 8) {
      currentValue = VMasker.toPattern(currentValue, "R$ 9.999,99");
    } else if (currentValue.length < 9) {
      currentValue = VMasker.toPattern(currentValue, "R$ 99.999,99");
    } else if (currentValue.length < 10) {
      currentValue = VMasker.toPattern(currentValue, "R$ 999.999,99");
    }

    return currentValue;
  };

  const dbz = (productID: string): string => {
    let obs = "";
    productSelected.forEach((item) => {
      if (item.id === productID) {
        obs = item.observation;
      }
    });

    return obs;
  };

  return (
    <>
      {show && (
        <ModalOverlay className="remove">
          <ModalWrapper className="remove">
            <UModalCalendar className="remove">
              <Toast
                {...{ showToast, setShowToast }}
                width={toastType === "success" ? "fit-content" : "375px"}
                margin="24px auto 0 auto"
                toastType={toastType}
                text={toastText}
              />

              <Flex
                bg="cardHeaderBackgroundColor"
                justifyContent="center"
                h="45px"
                align="center"
                mb="10px"
              >
                <Text fontSize="20px" color="cardHeaderTextColor" as="h1">
                  PLANEJAMENTO DAS AÇÕES COMERCIAIS
                </Text>
              </Flex>
              {/* {(addFinancialPlanning && (
                <Stack>
                  <TableFinancialPlanning data={FinancPlanTableData} />
                  <Flex justifyContent="flex-end" pr="5px">
                    <Button
                      fontSize="10.5px"
                      mr="5px"
                      p="5px 10px"
                      color="black"
                      h="28px"
                      w="80px"
                      border={`1px solid ${theme.colors.border}`}
                      bg="#FFE699"
                      onClick={() => closePopUp()}
                    >
                      Confirmar
                    </Button>
                    <Button
                      fontSize="10.5px"
                      mr="5px"
                      h="28px"
                      w="80px"
                      color="black"
                      border="1px solid #E7E6E6"
                      bg="#E7E6E6"
                      onClick={() => closePopUp()}
                    >
                      Cancelar
                    </Button>
                  </Flex>
                </Stack>
              )) || ( */}
              <ContainerFlex margin="5px 0 0" width="100%">
                <WrapperSubView padding="0">
                  <Container width="21%" minWidth="0" color="#7A7A7A">
                    <CustomerHeader stage="1" img={calendarIcon}>
                      <span>
                        <p>Período:</p>
                        <p>
                          Selecione a data <b>INICIAL</b> e <b>FINAL</b>
                        </p>
                      </span>
                    </CustomerHeader>

                    <ContainerInput padding>
                      <div>
                        <label htmlFor="start">Data Inicial</label>
                        <input
                          id="start"
                          type="date"
                          // min={today}
                          ref={refInitialDate}
                          onChange={(e) => handleInitialDate(e)}
                        />
                      </div>
                      <div className="calendar-div">
                        <label htmlFor="start">Data Final</label>
                        <input
                          id="finalDate"
                          type="date"
                          // min={today}
                          ref={refFinishDate}
                          onChange={(e) => handlefinishDate(e)}
                        />
                      </div>
                    </ContainerInput>

                    <span>
                      <b>Período Selecionado</b>
                      {`: de ${initialDate} até ${finishDate}`}
                    </span>
                  </Container>
                  <Container width="79%" minWidth="0" color="#7A7A7A">
                    <CustomerHeader stage="3" img={calendarIcon}>
                      <span>
                        <p>Lojas:</p>
                        <p>
                          Selecione as <b>LOJAS PARTICIPANTES</b>
                        </p>
                      </span>
                    </CustomerHeader>
                    <Container inline>
                      <Container width="25%" minWidth="0" color="#7A7A7A">
                        <ButtonExcelImport
                          buttonColor={bgButton}
                          buttonValue={buttonTitle}
                          setButtonColor={setBgButton}
                          setButtonValue={setButtonTitle}
                          onChangeTitleButton={handleTitleButton}
                          onChange={setListCnpjs}
                          fileName={fileName}
                          setFileName={setFileName}
                        />
                      </Container>
                      <Container
                        width="75%"
                        minWidth="0"
                        color="#7A7A7A"
                        inline
                      >
                        <CardUpload border>
                          <p>lojas selecionadas:</p>
                          <span>{listCnpjs?.length || "0"} Lojas</span>
                        </CardUpload>
                        <CardUpload border>
                          <p>lojas importadas:</p>
                          <span>
                            {payloadCNPJ?.activeStores.count || "0"} Lojas
                          </span>
                        </CardUpload>
                        <CardUpload border>
                          <p>Lojas com atendimento:</p>
                          <span>{withAttendance} Lojas</span>
                        </CardUpload>
                        <CardUpload border>
                          <p>Lojas sem atendimento:</p>
                          <span>{withOutAttendance} Lojas</span>
                        </CardUpload>
                        <CardUpload color="#C00000">
                          <p>lojas com erro:</p>
                          <span>
                            <b>
                              {payloadCNPJ?.invalidStores.count || "0"} Lojas
                            </b>
                          </span>
                        </CardUpload>
                        <CardUpload>
                          <p>Relatório de ERROS</p>
                          <button
                            type="button"
                            className="btn-download-excel"
                            onClick={() => downloadExcel()}
                            disabled={
                              !(
                                payloadCNPJ &&
                                payloadCNPJ.invalidStores.count > 0
                              )
                            }
                          >
                            <RiFileExcel2Fill />
                            <p>Download</p>
                          </button>
                        </CardUpload>
                      </Container>
                    </Container>
                  </Container>
                </WrapperSubView>
                <WrapperSubView padding="10px 0 0">
                  <Container width="21%" minWidth="0" color="#7A7A7A">
                    <CustomerHeader stage="2" img={calendarIcon}>
                      <span>
                        <p>Alavancas:</p>
                        <p>
                          Selecione o <b>TIPO DE ALAVANCA</b>
                        </p>
                      </span>
                    </CustomerHeader>
                    <span>
                      <b>Alavanca Selecionada: </b> {alavanca}
                    </span>

                    <ContainerAlavancas>
                      {leverages.data?.map((leverage) => (
                        <>
                          <div key={Math.random()}>
                            <ButtonAlavanca
                              type="button"
                              onClick={() =>
                                alavancaSelected === leverage.id
                                  ? handleSelectedAlavancas("Nenhuma", "", 0)
                                  : handleSelectedAlavancas(
                                      leverage.name,
                                      leverage.id,
                                      leverage.shelf_quantity
                                    )
                              }
                              border={alavancaSelected === leverage.id}
                              key={Math.random()}
                            >
                              <img
                                src={leverage.image_template}
                                alt=""
                                key={Math.random()}
                              />
                            </ButtonAlavanca>
                          </div>
                        </>
                      ))}
                    </ContainerAlavancas>
                  </Container>
                  <Container width="79%" minWidth="0" color="#7A7A7A">
                    <CustomerHeader stage="4" img={calendarIcon}>
                      <span>
                        <p>Produtos:</p>
                        <p>
                          Selecione os <b>PRODUTOS PARTICIPANTES</b>
                        </p>
                      </span>
                    </CustomerHeader>
                    {(products.isLoading && <LoadingComponent />) || (
                      <>
                        <Stack pt="5px" w="24.7%">
                          <ButtonExcelImport
                            buttonColor={bgButton}
                            buttonValue={buttonTitle}
                            setButtonColor={setBgButton}
                            setButtonValue={setButtonTitle}
                            onChangeTitleButton={handleTitleButton}
                            onChange={handleImportSkusByEAN}
                            fileName={fileNameImportSku}
                            setFileName={setFileNameImportSku}
                            hiddenButton
                          />
                          <Text py={2} textAlign="center">
                            -- OU --
                          </Text>
                        </Stack>
                        <span className="qtd-sku">
                          <b>Marcas Selecionadas:</b> [{marca}]
                          <p>
                            SKUs Selecionados
                            <b>{` [${productSelected.length}]`}</b>
                          </p>
                        </span>
                        <Flex
                          mt="7px"
                          justifyContent="space-between"
                          position="relative"
                        >
                          <Stack mr="10px" w="100%" position="relative">
                            <Text>Categoria</Text>
                            <Filters
                              // placeholder="Buscar caterogia..."
                              border="1px solid #000040"
                              width="100%"
                              mt="20px"
                              position="absolute"
                              selectedItemName={
                                filterSelected.category.nameFilter
                              }
                              filter={filterItemCategory}
                              listCategory={
                                SAllFilters.data.myExecution.subviewOSA
                                  .secondary.categories
                              }
                            />
                          </Stack>
                          <Stack mr="10px" w="100%" position="relative">
                            <Text>Marca</Text>
                            <Filters
                              width="100%"
                              position="absolute"
                              mt="20px"
                              border="1px solid #000040"
                              selectedItemName={filterSelected.brand.nameFilter}
                              filter={filterItemBrand}
                              listCategory={filterSelected.category.listFilter}
                            />
                          </Stack>
                          <Stack
                            mr="10px"
                            w="100%"
                            h="50px"
                            align="space-between"
                            position="relative"
                          >
                            <Text>Segmento</Text>
                            <Filters
                              width="100%"
                              position="absolute"
                              mt="20px"
                              border="1px solid #1d1d1d"
                              selectedItemName={filterSelected.segment}
                              filter={filterItemSegments}
                              listCategory={filterSelected.brand.listFilter}
                            />
                          </Stack>
                          <Stack w="100%" position="relative">
                            <Text>Msl</Text>
                            <Filters
                              width="100%"
                              position="absolute"
                              mt="20px"
                              border="1px solid #000040"
                              selectedItemName={filterSelected.isMSL}
                              filter={handleFilters}
                              listCategory={
                                INITIAL_STATE.promoFilter.priorityItems
                                  .listFilters || []
                              }
                            />
                          </Stack>
                        </Flex>

                        <ContainerTableModal>
                          <TableSubView
                            alignColumn={[2, 3, 4, 5]}
                            headers={[
                              "Descrição do Produto",
                              "Cod. EAN",
                              "Selecionado",
                              "Rebaixa de preço",
                              "Prateleiras",
                              "Observações",
                            ]}
                            dataTable={mainListProduct.map((product) => {
                              return {
                                descProd: product.name,
                                EAN: (
                                  <Flex justify="center">
                                    <Text>{product.ean}</Text>
                                  </Flex>
                                ),
                                selecionado: (
                                  <CustomRadio
                                    activeList={productSelected}
                                    id={product.id}
                                    setActiveList={setProductSelected}
                                    key={Math.random()}
                                  />
                                ),
                                tpr: (
                                  <div>
                                    <input
                                      type="text"
                                      placeholder="R$ 00,00"
                                      onChange={formatMoney}
                                      defaultValue={getValueInput(product.id)}
                                      disabled={handleSelectedItem(
                                        productSelected,
                                        product.id
                                      )}
                                      onBlur={addTPRinObject}
                                      id={product.id}
                                      key={Math.random()}
                                    />
                                  </div>
                                ),
                                prateleiras: (
                                  <Flex>
                                    {qtdPrateleiras === 0
                                      ? "Sem prateleiras"
                                      : mapQtdStands(product)}
                                  </Flex>
                                ),

                                observacoes: (
                                  <input
                                    type="text"
                                    placeholder="Observações importantes"
                                    defaultValue={dbz(product.id)}
                                    disabled={handleSelectedItem(
                                      productSelected,
                                      product.id
                                    )}
                                    onBlur={addObservation}
                                    name={product.id}
                                    key={Math.random()}
                                  />
                                ),
                              };
                            })}
                          />
                        </ContainerTableModal>
                      </>
                    )}
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      marginBottom="10px"
                    >
                      <Stack
                        color="black"
                        border="1px solid #e7e6e6"
                        p="5px"
                        justifyContent="center"
                      >
                        <Text
                          color="black"
                          background="#E7E6E6"
                          fontWeight="bold"
                          padding="5px"
                          textAlign="left"
                          fontSize="10.5px"
                        >
                          Insira o investimento TOTAL da ação
                        </Text>
                        <Flex>
                          <Image
                            src={moneyIcon}
                            alt="icon-investiment"
                            marginRight={6}
                            height="35px"
                          />
                          <Flex border="1px solid #e7e6e6">
                            <Input
                              type="text"
                              fontSize="18px"
                              width="170px"
                              placeholder="R$"
                              onChange={formatMoney}
                              maxLength={13}
                              border="none"
                            />
                          </Flex>
                        </Flex>
                      </Stack>
                      <Flex>
                        <ModalButton
                          type="button"
                          bg={theme.colors.button}
                          margin="auto 0 0 auto"
                          onClick={() => handleSaveButton()}
                          filter={filtButton}
                        >
                          {isSendingRequest ? (
                            <ChakraSpinner width="13px" height="13px" />
                          ) : (
                            "Salvar"
                          )}
                        </ModalButton>

                        <ModalButton
                          type="button"
                          bg="#E7E6E6"
                          margin="auto 0 0 6px"
                          color="#000"
                          onClick={() => {
                            setShow();
                            resetComponent();
                          }}
                        >
                          Cancelar
                        </ModalButton>
                      </Flex>
                    </Box>
                  </Container>
                </WrapperSubView>
              </ContainerFlex>
              {/* )} */}
            </UModalCalendar>
          </ModalWrapper>
        </ModalOverlay>
      )}
    </>
  );
};

ModalCalendar.defaultProps = {
  children: <div />,
};

const mapStateToProps = (state: GlobalProps) => ({
  SFilter: state.RSubFilter,
  SAllFilters: state.marketShareCategory,
});

export default connect(mapStateToProps)(ModalCalendar);
