import { useQuery, UseQueryResult } from "react-query";
import { filter } from "../../../../store/SideFilters/RequestFilters/types";
import { IStateFilters } from "../../../../store/SideFilters/types";
import api from "../../../api";

export enum ELavarage {
  PRESENCA = "PRESENCA",
  GONDOLA = "GONDOLA",
  PONTO_EXTRA = "PONTO_EXTRA",
}
export interface ISelectedFiltersKpi {
  selectPeriod: filter;
  selectedPresence: filter;
  selectedCategoryFilter: string;
  selectedBrandFilter: string;
}

export interface IKpiExcel {
  file: string;
}

const requestKpiProductExcel = async (
  filters: IStateFilters
): Promise<IKpiExcel> => {
  let filterCategorySideBar = {
    businessUnitId: filters.promoFilter.businessUnitId.selectedFilterId,
    category: filters.promoFilter.category.selectedFilterId,
    brand: filters.promoFilter.brand.selectedFilterId,
    segments: filters.promoFilter.segments.selectedFilterId,
  };

  if (filters.internalKpiProduct.selectedPresence.id !== ELavarage.PRESENCA) {
    filterCategorySideBar = {
      businessUnitId: "",
      category: filters.internalKpiProduct.selectedCategoryFilter,
      brand: filters.internalKpiProduct.selectedBrandFilter,
      segments: "",
    };
  }

  const result = await api.get<any>(`kpi_product_excel/`, {
    params: {
      period: filters.internalKpiProduct.selectPeriod.id,
      leverage: filters.internalKpiProduct.selectedPresence.id,
      businessUnit: filterCategorySideBar.businessUnitId || null,
      category: filterCategorySideBar.category || null,
      subCategory: filterCategorySideBar.brand || null,
      subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
      banner: filters.promoFilter.banner.selectedFilterId || null,
      region: filters.promoFilter.region.selectedFilterId || null,
      uf: filters.promoFilter.uf.selectedFilterId || null,
      director: filters.promoFilter.commercialDirector.selectedFilterId || null,
      manager: filters.promoFilter.commercialManager.selectedFilterId || null,
      customer_sales_rep:
        filters.promoFilter.commercialSaler.selectedFilterId || null,
      cnpj: filters.promoFilter.storeCNPJ || null,
    },
  });

  return result.data;
};

export const useRequestKpiProductExcel = (
  filters: IStateFilters
): UseQueryResult<IKpiExcel, unknown> => {
  return useQuery(
    ["presenceKpiProductExcel", filters],
    () => requestKpiProductExcel(filters),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};
