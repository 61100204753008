import { AxiosResponse } from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";
import { ITypeGraphicPeriod, ITypeGraphicPeriodBar } from "./onePageTypes";

interface IResponseFormatData {
  pbr: ITypeGraphicPeriodBar;
  pbl: ITypeGraphicPeriodBar;
  pl: ITypeGraphicPeriod;
  pr: ITypeGraphicPeriod;
}

const requestSelloutMarketEvolution = async (
  filters: IStateFilters,
  type: string
): Promise<AxiosResponse<IResponseFormatData>> => {
  const response = await api.get<IResponseFormatData>(
    "one_page_sell_out_market_evolution/",
    {
      params: {
        businessUnit: filters.filter.businessUnitId.selectedFilterId || null,
        category: filters.filter.category.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        is_promo: filters.promoFilter.merchandising.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        coordinator:
          filters.promoFilter.commercialCoordinator.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
        type: type || null,
      },
    }
  );
  return response;
};

export const useSelloutMarketEvolution = (
  filters: IStateFilters,
  type: string
): UseQueryResult<AxiosResponse<IResponseFormatData>> => {
  return useQuery(
    ["selloutMarketEvolution", filters, type],
    () => requestSelloutMarketEvolution(filters, type),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
};
