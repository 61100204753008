import {
  TFilterPromoBrand,
  TFilterPromoCategory,
  TFilterPromoSegment,
  TFilterSegment,
  TFilterManager,
  TFilterSalesRep,
  TFilterCoordinator,
  filter,
} from "./RequestFilters/types";

export enum EFilters {
  CHANGE_PROMO_FILTER = "@SideFilters/CHANGE_PROMO_FILTER",
  CHANGE_FILTER = "@SideFilters/CHANGE_FILTER",
  CHANGE_DATE_INITIAL_FILTER = "@SideFilters/CHANGE_DATE_INITIAL_FILTER",
  CHANGE_DATE_FINAL_FILTER = "@SideFilters/CHANGE_DATE_FINAL_FILTER",
  CHANGE_STORE_CNPJ_FILTER = "@SideFilters/CHANGE_STORE_CNPJ_FILTER",
  CHANGE_INTERNAL_FILTER_KPI_PRODUCT = "@SideFilters/CHANGE_INTERNAL_FILTER_KPI_PRODUCT",

  CHANGE_SELECTED_BANNER = "@SideFilters/CHANGE_SELECTED_BANNER",
  CHANGE_SELECTED_REGION = "@SideFilters/CHANGE_SELECTED_REGION",
}

export interface TFilterExecutionLeverage {
  id: string;
  label: string;
  executionTypeId: string;
}

export interface TFilterBanner {
  id: string;
  label: string;
  subChannelId: string;
}

export interface IFilter {
  id: string;
  label: string;
}

export interface TFilterRegion {
  id: string;
  label: string;
  regionId: string;
}

export interface TFilterCategory {
  id: string;
  label: string;
  businessUnitId: string;
}

export interface TFilterBrand {
  id: string;
  label: string;
  categoryId: string;
}

export interface TypeSegments {
  id: string;
  label: string;
  brandId: string;
}

export interface IChangePromoFilter {
  payload: IProductFilter & IPromoFilter;
  type: EFilters.CHANGE_PROMO_FILTER;
}

export interface IChangeFilter {
  payload: IProductFilter;
  type: EFilters.CHANGE_FILTER;
}

export interface IChangeDateInitialFilter {
  payload: string;
  type: EFilters.CHANGE_DATE_INITIAL_FILTER;
}

export interface IChangeDateFinalFilter {
  payload: string;
  type: EFilters.CHANGE_DATE_FINAL_FILTER;
}

export interface IChangeStoreCNPJ {
  payload: string;
  type: EFilters.CHANGE_STORE_CNPJ_FILTER;
}

export interface IChangeFilterKpiProduct {
  payload: ISelectedFiltersKpi;
  type: EFilters.CHANGE_INTERNAL_FILTER_KPI_PRODUCT;
}

export interface IFormatFilters {
  selectedFilterId: string;
  selectedFilterName: string;
  listFilters?:
    | TFilterExecutionLeverage[]
    | TFilterBanner[]
    | TFilterRegion[]
    | TFilterCategory[]
    | TFilterPromoCategory[]
    | TFilterBrand[]
    | TFilterPromoBrand[]
    | TFilterSegment[]
    | TFilterPromoSegment[]
    | TFilterManager[]
    | TFilterCoordinator[]
    | TFilterSalesRep[];
}

export interface IFormatFilterPriority {
  selectedFilterId: string;
  selectedFilterName: string;
  listFilters?: IFilter[];
}

export interface IProductFilter {
  businessUnitId: IFormatFilters;
  category: IFormatFilters;
  brand: IFormatFilters;
  segments: IFormatFilters;
}

export interface IPromoFilter {
  dateInitial: string;
  dateFinal: string;
  subChannel: IFormatFilters;
  banner: IFormatFilters;
  region: IFormatFilters;
  uf: IFormatFilters;
  executionType: IFormatFilters;
  executionLeverage: IFormatFilters;
  priorityItems: IFormatFilterPriority;
  commercialDirector: IFormatFilters;
  commercialManager: IFormatFilters;
  commercialCoordinator: IFormatFilters;
  commercialSaler: IFormatFilters;
  merchandising: IFormatFilterPriority;
  storeCNPJ: string;
}

export interface ISelectedFiltersKpi {
  selectPeriod: filter;
  selectedPresence: filter;
  selectedCategoryFilter: string;
  selectedBrandFilter: string;
  limitItem: number;
}

export type TPromoFilter = IProductFilter & IPromoFilter;

export interface IStateFilters {
  promoFilter: TPromoFilter;
  filter: IProductFilter;
  internalKpiProduct: ISelectedFiltersKpi;
}
