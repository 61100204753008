import React, { useContext } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { Stack, Flex, Image, Text, Box, Button } from "@chakra-ui/react";
import * as ANavigator from "../../store/Navigation/actions";
import {
  INavContent,
  ENavSubMenu,
  IMainMenu,
  INavMainMenu,
  stateType,
} from "../../store/Navigation/types";

import SideFilterMenu from "../SideFilterMenu/SideFilterMenu";
import LogoImage from "../../assets/TradexLogos/tradex.png";
import { GlobalProps } from "../../store";
import { useConfigSystem } from "../../services/Hooks/config/useConfigSystem";
import { FormatStringDataBr } from "../Graphics/Resource/GraphicsFunctions";

import { theme } from "../../styles/theme";
import { ConfigContext } from "../../services/Context/ConfigContext";
import { IStateFilters } from "../../store/SideFilters/types";
import Toast, { useToast } from "../Toast";
import { useFetchEvidences } from "../../services/Hooks/Evidences";
import { useRequestKpiProduct } from "../../services/Hooks/MyExecution/DetailKpiProduct/useRequestKpi";

interface IStateProps {
  INavigation: stateType;
  SFilters: IStateFilters;
}

interface IDispatchProps {
  ANavigation: {
    changeDash(content: INavContent): void;
    changeMenu(menuItem: IMainMenu): void;
    changeSubMenu(index: ENavSubMenu): void;
  };
}

type props = IStateProps & IDispatchProps;

const SideMenu: React.FC<props> = ({
  ANavigation,
  INavigation,
  SFilters,
}: props): JSX.Element => {
  const { config } = useContext(ConfigContext);
  const { showToast, setShowToast } = useToast();

  const { refetch } = useFetchEvidences(
    SFilters,
    config.general.promoIndustryId
  );

  const kpiProduct = useRequestKpiProduct(SFilters);

  const gotoHome = () => {
    let subMenu: ENavSubMenu = ENavSubMenu.ONE_PAGE;
    let title = "SELECTED MENU";

    if (config.modules.onePage.active) {
      subMenu = ENavSubMenu.ONE_PAGE;
      title = "ONE PAGE";
    } else if (config.modules.myExecution.active) {
      subMenu = ENavSubMenu.ME_NPD;
      title = "Minha Execução";
    } else if (config.modules.myNegotiation.active) {
      subMenu = ENavSubMenu.MN_CALENDAR;
      title = "Calendario Promocional";
    } else if (config.modules.promo.active) {
      subMenu = ENavSubMenu.MD_BOOK;
      title = "Calendario Promocional";
    }

    ANavigation.changeDash(INavContent.MAIN_DASH_CONTENT);
    ANavigation.changeMenu({
      index: INavMainMenu.MY_EXECUTION,
      name: title,
    });
    ANavigation.changeSubMenu(subMenu);
  };

  const validateGenerateBook = () => {
    if (
      SFilters.promoFilter.dateInitial === "dd/mm/aaaa" ||
      SFilters.promoFilter.dateFinal === "dd/mm/aaaa"
    ) {
      setShowToast();
      return;
    }
    refetch();
  };

  const { data } = useConfigSystem();

  const referenceDate =
    data &&
    (INavigation.menuItem.index === INavMainMenu.MY_ONE_PAGE
      ? config.general.referenceDate
      : config.general.referenceDateMyExecution);

  return (
    <>
      <Toast
        {...{ showToast, setShowToast }}
        width="380px"
        margin="24px auto 0 auto"
        toastType="error"
        text="Selecione uma data inicial e uma data final!"
      />
      <Stack maxW="230px" w="100%" h="100%" bg="sidebarColor">
        <Flex
          borderBottom="1px solid #e2e2e2"
          h="120px"
          mx="auto"
          w="98%"
          justify="center"
          align="center"
        >
          <Image
            cursor="pointer"
            src={LogoImage}
            onClick={() => gotoHome()}
            w="100px"
            h="100px"
          />
        </Flex>
        <Text
          as="h2"
          mt="15px"
          p="20px"
          fontSize="24px"
          fontWeight="bold"
          color="textColor"
          textAlign="center"
        >
          {config.general.industryName}
        </Text>
        <Stack align="center" pb="30px" mx="10px !important">
          <Text color="textColor">
            <b>Data Referência</b>
          </Text>
          <Box w="100%" bg="white" y="3px">
            <Text color="#000000" fontWeight="bold" textAlign="center">
              {(referenceDate && FormatStringDataBr(referenceDate)) ||
                "--/--/----"}
            </Text>
          </Box>
        </Stack>
        <Stack
          px="10px"
          flex={1}
          overflow="auto"
          css={{
            "&::-webkit-scrollbar": {
              width: "12px",
              height: "12px",
              borderRadius: "20px",
              backgroundColor: "darkgray",
            },
            "&::-webkit-scrollbar-thumb": {
              background: theme.scrollColor,
              borderRadius: "20px",
            },
          }}
        >
          <SideFilterMenu />
        </Stack>
        {(INavigation.indexSubMenu === ENavSubMenu.MD_BOOK && (
          <Button
            onClick={() => validateGenerateBook()}
            my="15px !important"
            py="20px"
            fontSize="14px"
            w="calc(100% - 20px)"
            cursor="pointer"
            color="black"
            lineHeight={0}
            mx="10px !important"
          >
            Gerar book
          </Button>
        )) ||
          (INavigation.contentDash === INavContent.ME_DETAIL_KPI && (
            <Button
              onClick={() => kpiProduct.refetch()}
              my="15px !important"
              py="20px"
              fontSize="14px"
              w="calc(100% - 20px)"
              cursor="pointer"
              color="black"
              lineHeight={0}
              mx="10px !important"
            >
              Filtrar
            </Button>
          ))}
        <Text textAlign="end" y="5px" pr="10px" color="textColor">
          TRADEX BIP v1.0.27
        </Text>
      </Stack>
    </>
  );
};

const mapStateToProps = (state: GlobalProps) => ({
  INavigation: state.navigation,
  IState: state.marketShareCategory,
  SFilters: state.RSubFilter,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    ANavigation: bindActionCreators(ANavigator, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
