export const theme = {
  colors: {
    sideBar: "#000040",
    header: "#000000",
    subMenu: "#ffffff",
    profile: "#a144ac",
    borderProfile: "#a144ac",
    text: "#ffffff",
    button: "#a144ac",
    border: "#004e4a",
    numberCards: "#A144AC",
    arrow: "#000000",
    profileSideBar: "#000000",
    textHeaderMenu: "#ffffff",
    borderHeaderMenu: "#ffffff",
  },
  graphics: {
    light: "#41c4be",
    dark: "#004e4a",
    period: "#c00000",
    target: "#c00000",
  },
  scrollColor: "#004e4a",
};
