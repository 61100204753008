import { useQuery, UseQueryResult } from "react-query";
import { IDataFormat } from "../../../components/TableComplements";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

const requestExtraExposureTable = async (
  period: string,
  filters: IStateFilters
): Promise<IDataFormat> => {
  const { data } = await api.get<{ data: IDataFormat }>(
    `extra_exposure_tables/?period=${period}`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        coordinator:
          filters.promoFilter.commercialCoordinator.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
        // category: filters.category.selectedFilterId || null,
      },
    }
  );

  return data.data;
};

export const useRequestExtraExposureTable = (
  period: string,
  filters: IStateFilters
): UseQueryResult<IDataFormat, unknown> => {
  return useQuery(
    ["extraExposureTable", period, filters],
    () => requestExtraExposureTable(period, filters),
    {
      refetchOnWindowFocus: false,
    }
  );
};
