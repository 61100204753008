import { useQuery, UseQueryResult } from "react-query";
import { filter } from "../../../../store/SideFilters/RequestFilters/types";
import { IStateFilters } from "../../../../store/SideFilters/types";
import api from "../../../api";

export interface ISelectedFiltersKpi {
  selectPeriod: filter;
  selectedPresence: filter;
  selectedCategoryFilter: string;
  selectedBrandFilter: string;
}

export interface IKpiExcel {
  file: string;
}

const requestKpiStoreExcel = async (
  internalFilters: ISelectedFiltersKpi,
  filters: IStateFilters
): Promise<IKpiExcel> => {
  const result = await api.get<any>(
    `kpi_store_excel/?period=${internalFilters.selectPeriod.id}&leverage=${internalFilters.selectedPresence.id}&category=${internalFilters.selectedCategoryFilter}&subCategory=${internalFilters.selectedBrandFilter}`,
    {
      params: {
        businessUnit:
          filters.promoFilter.businessUnitId.selectedFilterId || null,
        category: filters.promoFilter.category.selectedFilterId || null,
        subCategory: filters.promoFilter.brand.selectedFilterId || null,
        subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
        banner: filters.promoFilter.banner.selectedFilterId || null,
        region: filters.promoFilter.region.selectedFilterId || null,
        uf: filters.promoFilter.uf.selectedFilterId || null,
        director:
          filters.promoFilter.commercialDirector.selectedFilterId || null,
        manager: filters.promoFilter.commercialManager.selectedFilterId || null,
        customer_sales_rep:
          filters.promoFilter.commercialSaler.selectedFilterId || null,
        cnpj: filters.promoFilter.storeCNPJ || null,
      },
    }
  );

  return result.data;
};

export const useRequestKpiStoreExcel = (
  internalFilters: ISelectedFiltersKpi,
  filters: IStateFilters
): UseQueryResult<IKpiExcel, unknown> => {
  return useQuery(
    ["presenceKpiStoreExcel", internalFilters, filters],
    () => requestKpiStoreExcel(internalFilters, filters),
    {
      refetchOnWindowFocus: false,
    }
  );
};
