import { useQuery, UseQueryResult } from "react-query";
import { IMonTable } from "../../../../components/Table/Column";
import { IStateFilters } from "../../../../store/SideFilters/types";
import api from "../../../api";
import { ITypeGraphicHorizontalBar } from "../../OnePage/onePageTypes";

enum IStatusCompliance {
  SUCCESS = 1,
  FAILED = 0,
  ALERT = 2,
  VOID = -1,
}

interface IHeaderCompliance {
  label: string;
  value: string;
  executed: string;
  negotiated: string;
}

interface IContentCompliance {
  icon: IStatusCompliance;
  label?: string;
}

interface ICompliceStructure {
  descriptionStore: IMonTable[];
  complianceData: {
    headers: IHeaderCompliance[];
    subHeaders: string[][];
    content: IContentCompliance[][];
    growth: ITypeGraphicHorizontalBar;
  };
}

export interface IHeaderPanelData {
  compliance: string;
  total: string;
  withCompliance: string;
  withoutCompliance: string;
}

interface IInfoPanel {
  compliance: string;
  leverages: IHeaderCompliance[];
  store: IHeaderPanelData;
}

export interface ICompliceStoreData {
  total: number;
  info: IInfoPanel;
  data: ICompliceStructure;
}

const requestComplianceStore = async (
  filters: IStateFilters
): Promise<ICompliceStoreData> => {
  const presence = await api.get<ICompliceStoreData>(`compliance_detail/`, {
    params: {
      category: filters.promoFilter.category.selectedFilterId || null,
      subCategory: filters.promoFilter.brand.selectedFilterId || null,
      subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
      banner: filters.promoFilter.banner.selectedFilterId || null,
      region: filters.promoFilter.region.selectedFilterId || null,
      uf: filters.promoFilter.uf.selectedFilterId || null,
      director: filters.promoFilter.commercialDirector.selectedFilterId || null,
      manager: filters.promoFilter.commercialManager.selectedFilterId || null,
      coordinator: filters.promoFilter.commercialCoordinator.selectedFilterId || null,
      customer_sales_rep:
        filters.promoFilter.commercialSaler.selectedFilterId || null,
      cnpj: filters.promoFilter.storeCNPJ || null,
    },
  });

  return presence.data;
};

export const useRequestComplianceStore = (
  filters: IStateFilters
): UseQueryResult<ICompliceStoreData, unknown> => {
  return useQuery(
    ["complianceStore", filters],
    () => requestComplianceStore(filters),
    {
      refetchOnWindowFocus: false,
    }
  );
};
