import {
  filter,
  stateMarketShare,
} from "../../../store/SideFilters/RequestFilters/types";
import {
  IStateFilters,
  IFilter,
  TPromoFilter,
  IProductFilter,
} from "../../../store/SideFilters/types";
import { INITIAL_STATE } from "../../../store/SideFilters";

export interface IDispatchFilters {
  changeFilterIsPromo(payload: TPromoFilter): void;
  changeFilter(payload: IProductFilter): void;
}

export const filterExecutionType = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters
): void => {
  let itemSelected: filter = { id: "", label: "" };

  SAllFilters.data.myExecution.subviewOSA.main.executions_type.forEach(
    (item) => {
      if (item.id === id) {
        itemSelected = item;
      }
    }
  );

  const filtredItens =
    SAllFilters.data.myExecution.subviewOSA.secondary.execution_leverage
      .filter((filterItem) => id === filterItem.executionTypeId)
      .map((filtred) => {
        return filtred;
      });

  DFilters.changeFilterIsPromo({
    ...SFilters.promoFilter,
    executionType: {
      selectedFilterId: id,
      selectedFilterName: itemSelected.label,
    },
    executionLeverage: {
      selectedFilterId: "",
      selectedFilterName: "",
      listFilters: filtredItens,
    },
  });
};

export const filterExecutionLeverage = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters
): void => {
  let itemSelected: filter = { id: "", label: "" };

  SAllFilters.data.myExecution.subviewOSA.secondary.execution_leverage.forEach(
    (item) => {
      if (item.id === id) {
        itemSelected = item;
      }
    }
  );

  DFilters.changeFilterIsPromo({
    ...SFilters.promoFilter,
    executionLeverage: {
      selectedFilterId: id,
      selectedFilterName: itemSelected.label,
      listFilters: SFilters.promoFilter.executionLeverage.listFilters,
    },
  });
};

export const filterBusinessUnitId = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters,
  isPromo: boolean
): void => {
  let itemSelected: filter = { id: "", label: "" };
  const listBusinessUnit = isPromo
    ? SAllFilters.data.myExecution.subviewOSA.main.business_unit_promo
    : SAllFilters.data.myExecution.subviewOSA.main.business_unit;

  listBusinessUnit.forEach((item) => {
    if (item.id === id) {
      itemSelected = item;
    }
  });

  let filtredItens;
  if (isPromo) {
    filtredItens =
      SAllFilters.data?.myExecution.subviewOSA.secondary.categories_promo
        .filter(
          (filterItem) => itemSelected.id === filterItem.businessPromoUnitId
        )
        .map((filtred) => {
          return filtred;
        });
    DFilters.changeFilterIsPromo({
      ...SFilters.promoFilter,
      businessUnitId: {
        selectedFilterId: id,
        selectedFilterName: itemSelected.label,
      },
      category: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: filtredItens,
      },
      brand: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: [],
      },
      segments: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: [],
      },
    });
  } else {
    filtredItens = SAllFilters.data?.myExecution.subviewOSA.secondary.categories
      .filter((filterItem) => itemSelected.id === filterItem.businessUnitId)
      .map((filtred) => {
        return filtred;
      });

    DFilters.changeFilter({
      ...SFilters.filter,
      businessUnitId: {
        selectedFilterId: id,
        selectedFilterName: itemSelected.label,
      },
      category: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: filtredItens,
      },
      brand: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: [],
      },
      segments: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: [],
      },
    });
  }
};

export const filterCategory = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters,
  isPromo: boolean
): void => {
  let itemSelected: IFilter = { id: "", label: "" };

  const listCategories = isPromo
    ? SAllFilters.data.myExecution.subviewOSA.secondary.categories_promo
    : SAllFilters.data.myExecution.subviewOSA.secondary.categories;

  listCategories.forEach((item) => {
    if (item.id === id) {
      itemSelected = item;
    }
  });

  let filtredItens;
  if (isPromo) {
    filtredItens =
      SAllFilters.data?.myExecution.subviewOSA.secondary.brands_promo
        .filter((filterItem) => itemSelected.id === filterItem.categoryPromoId)
        .map((filtred) => {
          return filtred;
        });

    DFilters.changeFilterIsPromo({
      ...SFilters.promoFilter,
      category: {
        selectedFilterId: id,
        selectedFilterName: itemSelected.label,
        listFilters: SFilters.promoFilter.category.listFilters,
      },
      brand: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: filtredItens,
      },
      segments: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: [],
      },
    });
  } else {
    filtredItens = SAllFilters.data?.myExecution.subviewOSA.secondary.brands
      .filter((filterItem) => itemSelected.id === filterItem.categoryId)
      .map((filtred) => {
        return filtred;
      });

    DFilters.changeFilter({
      ...SFilters.filter,
      category: {
        selectedFilterId: id,
        selectedFilterName: itemSelected.label,
        listFilters: SFilters.filter.category.listFilters,
      },
      brand: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: filtredItens,
      },
      segments: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: [],
      },
    });
  }
};

export const filterBrand = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters,
  isPromo: boolean
): void => {
  let itemSelected: IFilter = { id: "", label: "" };

  const listBrand = isPromo
    ? SAllFilters.data.myExecution.subviewOSA.secondary.brands_promo
    : SAllFilters.data.myExecution.subviewOSA.secondary.brands;

  listBrand.forEach((item) => {
    if (item.id === id) {
      itemSelected = item;
    }
  });

  let filtredItens;

  if (isPromo) {
    filtredItens =
      SAllFilters.data.myExecution.subviewOSA.secondary.segments_promo
        .filter((filterItem) => itemSelected.id === filterItem.brandPromoId)
        .map((filtred) => {
          return filtred;
        });

    DFilters.changeFilterIsPromo({
      ...SFilters.promoFilter,
      brand: {
        selectedFilterId: id,
        selectedFilterName: itemSelected.label,
        listFilters: SFilters.promoFilter.brand.listFilters,
      },
      segments: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: filtredItens,
      },
    });
  } else {
    filtredItens = SAllFilters.data.myExecution.subviewOSA.secondary.segments
      .filter((filterItem) => itemSelected.id === filterItem.brandId)
      .map((filtred) => {
        return filtred;
      });

    DFilters.changeFilter({
      ...SFilters.filter,
      brand: {
        selectedFilterId: id,
        selectedFilterName: itemSelected.label,
        listFilters: SFilters.promoFilter.category.listFilters,
      },
      segments: {
        selectedFilterId: "",
        selectedFilterName: "",
        listFilters: filtredItens,
      },
    });
  }
};

export const filterSegments = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters,
  isPromo: boolean
): void => {
  let itemSelected: filter = { id: "", label: "" };

  const listSegments = isPromo
    ? SAllFilters.data.myExecution.subviewOSA.secondary.segments_promo
    : SAllFilters.data.myExecution.subviewOSA.secondary.segments;

  listSegments.forEach((item) => {
    if (item.id === id) {
      itemSelected = item;
    }
  });

  if (isPromo) {
    DFilters.changeFilterIsPromo({
      ...SFilters.promoFilter,
      segments: {
        selectedFilterId: id,
        selectedFilterName: itemSelected.label,
      },
    });
  } else {
    DFilters.changeFilter({
      ...SFilters.filter,
      segments: {
        selectedFilterId: id,
        selectedFilterName: itemSelected.label,
      },
    });
  }
};

export const filterSubChannel = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters
): void => {
  let itemSelected: filter = { id: "", label: "" };

  SAllFilters.data.marketShare.subviewCategory.main.subChannel.forEach(
    (item) => {
      if (item.id === id) {
        itemSelected = item;
      }
    }
  );

  const filtredItens =
    SAllFilters.data.marketShare?.subviewCategory.secondary.banner
      .filter((filterItem) => id === filterItem.subChannelId)
      .map((filtred) => {
        return filtred;
      });

  DFilters.changeFilterIsPromo({
    ...SFilters.promoFilter,
    subChannel: {
      selectedFilterId: id,
      selectedFilterName: itemSelected.label,
    },
    banner: {
      selectedFilterId: "",
      selectedFilterName: "",
      listFilters: filtredItens,
    },
  });
};

export const filterBanner = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters
): void => {
  let itemSelected: filter = { id: "", label: "" };

  SAllFilters.data.marketShare.subviewCategory.secondary.banner.forEach(
    (item) => {
      if (item.id === id) {
        itemSelected = item;
      }
    }
  );

  DFilters.changeFilterIsPromo({
    ...SFilters.promoFilter,
    banner: {
      selectedFilterId: id,
      selectedFilterName: itemSelected.label,
      listFilters: SFilters.promoFilter.banner.listFilters,
    },
  });
};

export const filterRegion = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters
): void => {
  let itemSelected: filter = { id: "", label: "" };

  SAllFilters.data.marketShare.subviewCategory.main.region.forEach((item) => {
    if (item.id === id) {
      itemSelected = item;
    }
  });

  const filtredItens =
    SAllFilters.data.marketShare?.subviewCategory.secondary.uf
      .filter((filterItem) => id === filterItem.regionId)
      .map((filtred) => {
        return filtred;
      });

  DFilters.changeFilterIsPromo({
    ...SFilters.promoFilter,
    region: {
      selectedFilterId: id,
      selectedFilterName: itemSelected.label,
    },
    uf: {
      selectedFilterId: "",
      selectedFilterName: "",
      listFilters: filtredItens,
    },
  });
};

export const filterUF = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters
): void => {
  let itemSelected: filter = { id: "", label: "" };

  SAllFilters.data.marketShare.subviewCategory.secondary.uf.forEach((item) => {
    if (item.id === id) {
      itemSelected = item;
    }
  });

  DFilters.changeFilterIsPromo({
    ...SFilters.promoFilter,
    uf: {
      selectedFilterId: id,
      selectedFilterName: itemSelected.label,
      listFilters: SFilters.promoFilter.uf.listFilters,
    },
  });
};

export const filterMerchandising = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters
): void => {
  let itemSelected: filter = { id: "", label: "" };

  SFilters.promoFilter.merchandising.listFilters?.forEach((item) => {
    if (item.id === id) {
      itemSelected = item;
    }
  });

  DFilters.changeFilterIsPromo({
    ...SFilters.promoFilter,
    merchandising: {
      selectedFilterId: id,
      selectedFilterName: itemSelected.label,
      listFilters: SFilters.promoFilter.merchandising.listFilters,
    },
  });
};

export const filterDirector = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters
): void => {
  let itemSelected: IFilter = { id: "", label: "" };

  SAllFilters.data.commercial_structure.director.forEach((item) => {
    if (item.id === id) {
      itemSelected = item;
    }
  });

  const filtredItens = SAllFilters.data?.commercial_structure.manager
    .filter((filterItem) => itemSelected.id === filterItem.director_id)
    .map((filtred) => {
      return filtred;
    });

  DFilters.changeFilterIsPromo({
    ...SFilters.promoFilter,
    commercialDirector: {
      selectedFilterId: id,
      selectedFilterName: itemSelected.label,
      listFilters: SFilters.filter.category.listFilters,
    },
    commercialManager: {
      selectedFilterId: "",
      selectedFilterName: "",
      listFilters: filtredItens,
    },
    commercialCoordinator: {
      selectedFilterId: "",
      selectedFilterName: "",
      listFilters: filtredItens,
    },
    commercialSaler: {
      selectedFilterId: "",
      selectedFilterName: "",
      listFilters: [],
    },
  });
};

export const filterManage = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters
): void => {
  let itemSelected: IFilter = { id: "", label: "" };

  SFilters.promoFilter.commercialManager.listFilters?.forEach((item) => {
    if (item.id === id) {
      itemSelected = item;
    }
  });

  const filtredItens = SAllFilters.data.commercial_structure.coordinator
    .filter((filterItem) => itemSelected.id === filterItem.manager)
    .map((filtred) => {
      return filtred;
    });

  DFilters.changeFilterIsPromo({
    ...SFilters.promoFilter,
    commercialManager: {
      selectedFilterId: id,
      selectedFilterName: itemSelected.label,
      listFilters: SFilters.promoFilter.commercialManager.listFilters,
    },
    commercialCoordinator: {
      selectedFilterId: "",
      selectedFilterName: "",
      listFilters: filtredItens,
    },
    commercialSaler: {
      selectedFilterId: "",
      selectedFilterName: "",
      listFilters: filtredItens,
    },
  });
};

export const filterCoordinator = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters
): void => {
  let itemSelected: IFilter = { id: "", label: "" };

  SFilters.promoFilter.commercialCoordinator.listFilters?.forEach((item) => {
    if (item.id === id) {
      itemSelected = item;
    }
  });

  const filtredItens = SAllFilters.data.commercial_structure.cutomer_sales_rep
    .filter((filterItem) => itemSelected.id === filterItem.coordinator)
    .map((filtred) => {
      return filtred;
    });

  DFilters.changeFilterIsPromo({
    ...SFilters.promoFilter,
    commercialCoordinator: {
      selectedFilterId: id,
      selectedFilterName: itemSelected.label,
      listFilters: SFilters.promoFilter.commercialCoordinator.listFilters,
    },
    commercialSaler: {
      selectedFilterId: "",
      selectedFilterName: "",
      listFilters: filtredItens,
    },
  });
};

export const filterSales = (
  id: string,
  SFilters: IStateFilters,
  SAllFilters: stateMarketShare,
  DFilters: IDispatchFilters
): void => {
  let itemSelected: filter = { id: "", label: "" };

  SFilters.promoFilter.commercialSaler.listFilters?.forEach((item) => {
    if (item.id === id) {
      itemSelected = item;
    }
  });

  DFilters.changeFilterIsPromo({
    ...SFilters.promoFilter,
    commercialSaler: {
      selectedFilterId: id,
      selectedFilterName: itemSelected.label,
      listFilters: SFilters.promoFilter.commercialSaler.listFilters,
    },
  });
};

export const filterItemPriority = (id: string): void => {
  let itemSelected: filter = { id: "", label: "" };

  INITIAL_STATE.promoFilter.priorityItems.listFilters?.forEach(
    (item: IFilter) => {
      if (item.id === id) {
        itemSelected = item;
      }
    }
  );

  // DFilters.changePriorityItemFilter({
  //   selectedFilterId: itemSelected.id,
  //   selectedFilterName: itemSelected.label,
  //   listFilters: [],
  // });
};
