import { put, call, CallEffect, PutEffect } from "redux-saga/effects";
import api from "../../../services/api";
import { stateMarketShare, ILoadError, ILoadSuccess } from "./types";
import { loadSuccess, loadFailure } from "./actions";

export function* load(): Generator<
  CallEffect<unknown> | PutEffect<ILoadSuccess> | PutEffect<ILoadError>,
  void,
  stateMarketShare
> {
  try {
    const response: stateMarketShare = yield call(api.get, "filters/");
    const filterEvidence = [
      {
        id: "00281e29-f30d-4c77-9473-c0d022e8e7e5",
        label: "Displays de Check Out",
        executionTypeId: "3fc991a2-ae9b-4f3f-9a60-903c0605afa6",
      },
      {
        id: "e5739902-09cc-4a84-8f2d-f3bef0641f81",
        label: "ClipStrip",
        executionTypeId: "3fc991a2-ae9b-4f3f-9a60-903c0605afa6",
      },
      {
        id: "253c979a-0f63-4d16-a461-4f444da42994",
        label: "Gancheira",
        executionTypeId: "3fc991a2-ae9b-4f3f-9a60-903c0605afa6",
      },
      {
        id: "2c1b60e7-0422-4cba-86c1-f2ebe8ad8911",
        label: "Rebaixa/TPR",
        executionTypeId: "3fc991a2-ae9b-4f3f-9a60-903c0605afa6",
      },
      {
        id: "d2e50198-3c6a-493b-a4b9-26e295bfa5b7",
        label: "Ponta de Gôndola",
        executionTypeId: "3fc991a2-ae9b-4f3f-9a60-903c0605afa6",
      },
      {
        id: "0c8064b3-c9bf-455c-a468-f48c189a71ba",
        label: "Planograma",
        executionTypeId: "8609ffb8-440a-4207-99a9-354ea9ef43c6",
      },
      {
        id: "d1cc28f3-eb88-40c4-a4cd-1b4d0f69941f",
        label: "Cestão",
        executionTypeId: "3fc991a2-ae9b-4f3f-9a60-903c0605afa6",
      },
      {
        id: "ba54ecba-fe85-4efc-a544-980870b879d7",
        label: "CheckOut",
        executionTypeId: "3fc991a2-ae9b-4f3f-9a60-903c0605afa6",
      },
      {
        id: "72be675d-4698-4d8e-8906-9664df880084",
        label: "Mesa de Ofertas",
        executionTypeId: "3fc991a2-ae9b-4f3f-9a60-903c0605afa6",
      },
      {
        id: "765ee1d5-6248-4a52-9d77-eebdfb4c57e6",
        label: "Display de Chão",
        executionTypeId: "3fc991a2-ae9b-4f3f-9a60-903c0605afa6",
      },
    ];
    response.data.myExecution.subviewOSA.secondary.execution_leverage.push(
      ...filterEvidence
    );

    yield put(loadSuccess(response));
  } catch (e) {
    yield put(loadFailure());
  }
}
