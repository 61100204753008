import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

interface IMonths {
  name: string;
  values: string[];
}

export interface IMonthLavarege {
  LavaregeName: string;
  month: IMonths[];
  quarter: string[];
}

export interface IStructTable {
  calendar: {
    industry: {
      logo: string;
      data: {
        typeLaverage: string;
        lavarage: IMonthLavarege[];
      }[];
    };
    categories: {
      logoBrand: string;
      itens: {
        itemChannel: string;
        data: {
          typeLaverage: string;
          lavarages: IMonthLavarege[];
        }[];
      }[];
    }[];
  };
  months: string[];
}

interface IResponseRequest {
  data: IStructTable;
  total: number;
}

const requestCalendar = async (
  filters: IStateFilters
): Promise<IResponseRequest> => {
  const promoCalendar = await api.get<IResponseRequest>(`calendar/`, {
    params: {
      // category: filters.promoFilter.category.selectedFilterId || null,
      // subCategory: filters.promoFilter.brand.selectedFilterId || null,
      // segment: filters.promoFilter.segments.selectedFilterId || null,
      subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
      banner: filters.promoFilter.banner.selectedFilterId || null,
      region: filters.promoFilter.region.selectedFilterId || null,
      uf: filters.promoFilter.uf.selectedFilterId || null,
      director: filters.promoFilter.commercialDirector.selectedFilterId || null,
      manager: filters.promoFilter.commercialManager.selectedFilterId || null,
      coordinator: filters.promoFilter.commercialCoordinator.selectedFilterId || null,
      customer_sales_rep:
        filters.promoFilter.commercialSaler.selectedFilterId || null,
      cnpj: filters.promoFilter.storeCNPJ || null,
    },
  });

  return {
    data: promoCalendar.data.data,
    total: promoCalendar.data.total,
  };
};

export const useRequestCalendar = (
  filters: IStateFilters
): UseQueryResult<IResponseRequest, unknown> => {
  return useQuery(["promoCalendar", filters], () => requestCalendar(filters), {
    refetchOnWindowFocus: false,
  });
};
